.submitCartSection {
  margin-top: 15px;
  gap: 15px;
}
.submitCartOptions {
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 5px;
  padding: 15px;
}
.addressesTitle {
  font-size: 1.15em;
  font-weight: 500;
  color: var(--clr3);
  gap: 5px;
}
.addressesTitle svg {
  transform: translateY(-2px);
}
.addAddress {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--blueClr1);
  padding: 3px 5px 3px 7px;
  border-radius: 5px;
  gap: 3px;
  transition: background-color 0.4s;
}
.addAddress:hover {
  background-color: var(--blueClrOp1);
}
.emptyAddress {
  margin-top: 15px;
}
.noAddressText {
  color: var(--clr7);
  font-size: 0.95em;
  font-weight: 500;
  color: var(--clr3);
}
.addressesList {
  margin-top: 15px;
  gap: 10px;
}
.userAddressLabel {
  font-size: 0.95em;
  color: var(--clr3);
  font-weight: 500;
  gap: 7px;
  padding: 7px;
  position: relative;
  border: 1px dashed transparent;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.4s;
}
.userAddressLabel:hover {
  background-color: var(--blackOp07);
}
.userAddressLabel::after {
  content: "";
  width: calc(100% - 25px);
  height: 1px;
  background-color: var(--blackOp12);
  position: absolute;
  left: 0;
  bottom: -5px;
}
.userAddressLabel:last-child::after {
  display: none;
}
.userAddressLabel.activeLabel {
  background-color: var(--blackOp07);
  border-radius: 4px;
  border: 1px dashed var(--blackOp12);
  cursor: pointer;
}
/* discountCodeDiv */
.discountCodeDiv {
  margin-top: 25px;
  border-top: 1px solid var(--blackOp12);
  padding-top: 15px;
}
.discountInput {
  padding-left: 60px;
  margin-top: 10px;
}
.submitDiscountCode {
  position: absolute;
  left: 5px;
  bottom: 5px;
  border: 1px solid var(--greenClr1);
  border-radius: 4px;
  padding: 3px 15px;
  color: var(--clr3);
  font-weight: 500;
  background-color: rgba(28, 129, 28, 0.082);
  transition: background-color 0.4s;
}
.submitDiscountCode:hover {
  background-color: rgba(28, 129, 28, 0.185);
}
.discountWrong {
  color: var(--redClr);
  margin: 7px 5px 0 0;
  font-size: 0.9em;
  font-weight: 500;
}
.hasDicountDiv {
  margin-top: 5px;
  padding: 0 5px;
}
.hasDicountText {
  font-size: 0.95em;
  font-weight: 500;
  color: var(--greenClr1);
}
.hasCodeRemoveDiscount {
  border: 1px solid var(--redClr);
  border-radius: 4px;
  background-color: rgba(255, 99, 71, 0.13);
  height: 28px;
  padding: 0 10px;
  font-size: 0.8em;
  font-weight: 500;
  color: var(--redClr);
  transition: filter 0.4s;
}
.hasCodeRemoveDiscount:hover {
  filter: contrast(0.9);
}
.submitSummaryDiv {
  background-color: var(--bgClr);
  border-radius: 5px;
  box-shadow: 0 0 15px var(--blackOp12);
  padding: 10px 15px;
  gap: 10px;
}
.submitCartSummaryItem {
  padding: 7px 0;
  position: relative;
}
.submitCartSummaryItem::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--blackOp12);
  position: absolute;
  left: 0;
  bottom: -5px;
}
.submitCartSummaryItem:last-child::after {
  display: none;
}
.submitCartItemText {
  font-size: 1em;
  font-weight: 500;
  color: var(--clr3);
}
.submitCartPriceText {
  font-size: 0.98em;
  font-weight: 500;
  color: var(--clr5);
}
.submitCartPriceText span {
  margin-right: 5px;
  color: var(--clr7);
  font-size: 0.8em;
}
.submitCartBtn {
  color: var(--bgClr);
  font-size: 1em;
  font-weight: 500;
  background-color: var(--blueClr2);
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  transition: filter 0.4s;
}
.submitCartBtn:hover {
  filter: contrast(0.8);
}
.submitCartBtn.disable {
  background-color: var(--clrA);
}
@media screen and (min-width: 768px) {
  .submitCartSection {
    grid-template-columns: 1fr 280px;
  }
  .submitSummaryDiv {
    height: fit-content;
  }
}
@media screen and (min-width: 968px) {
  .submitCartSection {
    grid-template-columns: 1fr 320px;
  }
}
