.navsListContainer {
  margin-top: 15px;
}
.navsList {
  gap: 25px;
  flex-wrap: wrap;
  background-color: var(--bgClr);
  padding: 12px 15px;
  border-radius: 7px;
  box-shadow: 0 0 15px var(--blackOp12);
}
.navsListItem {
  color: var(--clr3);
  opacity: 0.7;
}
.navsListItem::after,
.navsListItem::before {
  content: "";
  width: 2px;
  height: 6px;
  background-color: var(--clr3);
  top: 45%;
  position: absolute;
  left: -14px;
  transform: translateY(-60%) rotate(45deg);
  opacity: 0.4;
}
.navsListItem::before {
  top: 55%;
  transform: translateY(-60%) rotate(-45deg);
}
.navsListItem:last-child {
  pointer-events: none;
  opacity: 1;
}
.navsListItem:last-child::after,
.navsListItem:last-child::before {
  display: none;
}
.homeNavItem {
  gap: 7px;
}
.homeNavItem svg {
  transform: translateY(-2px);
  width: 16px;
  height: 16px;
  color: var(--clr3);
  opacity: 0.7;
}
@media screen and (min-width: 649px) {
  .navsList {
    background-color: transparent;
    box-shadow: none;
    padding: 12px 0;
  }
}
