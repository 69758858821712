.searchMain .catItemLink * {
  pointer-events: none;
}
.searchResultGrid {
  gap: 20px;
}
.searchResultListItem {
  grid-template-columns: 80px 1fr;
  padding: 15px;
  border-radius: 7px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  gap: 20px;
  align-items: center;
}
.searchResultImg {
  width: 100%;
  border-radius: 5px;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.searchItemTitle {
  width: fit-content;
}
.searchItemBottomDiv {
  margin-top: auto;
}
.resultAt {
  color: var(--blueClr1);
  width: fit-content;
  border-radius: 5px;
  margin-top: 5px;
}
.searchPrice {
  gap: 5px;
  color: var(--redClr);
}
.resultItemFree {
  color: rgb(69, 192, 69);
}
@media screen and (min-width: 768px) {
  .searchResultGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .searchResultListItem {
    grid-template-columns: 1fr;
  }
  .searchItemBottomDiv {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
    gap: 0;
  }
  .resultAt {
    margin-right: auto;
  }
}
@media screen and (min-width: 1250px) {
  .searchResultGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .searchResultListItem {
    grid-template-columns: 1fr;
  }
}
