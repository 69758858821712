footer {
  background-color: #333;
  margin-top: 50px;
}
.footerContainer {
  margin-top: 40px;
  padding: 0 40px 20px;
}
.scrollToTopBtn {
  height: 35px;
  width: 35px;
  color: var(--bgClr);
  border-radius: 5px;
  border: 1px solid var(--bgClr);
  margin-right: auto;
  cursor: pointer;
  z-index: 40;
}
.scrollToTopBtn svg {
  height: 20px;
  width: 20px;
}
.footerGrid {
  gap: 60px;
  margin-top: 30px;
}
.footerDesc {
  color: var(--bgClr);
  text-align: justify;
  font-size: 1.05em;
}
.footerLinkTitle {
  color: var(--bgClr);
  font-size: 1.04em;
}
.popularFooterLink {
  gap: 5px;
  margin-top: 15px;
}
.footerLinkItem {
  padding-right: 15px;
  color: #ccc;
  font-size: 0.95em;
  width: fit-content;
}
.footerLinkItem::before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  right: 0;
  top: 9px;
  background-color: #ccc;
  border-radius: 50%;
  opacity: 0.8;
}
.contactUsTitle {
  font-size: 1.05em;
}
.footerCall,
.footerEmail,
.footerAddress {
  color: #ccc;
  gap: 8px;
  font-size: 0.98em;
  margin-top: 20px;
  width: fit-content;
}
.footerCall span {
  direction: ltr;
}
.footerCall svg,
.footerEmail svg,
.footerAddress svg {
  width: 20px;
  height: 20px;
}
.footerEmail,
.footerAddress {
  margin-top: 7px;
}
.footerSocialLinks {
  gap: 10px;
  margin-top: 30px;
}
.footerSocailItem {
  color: var(--bgClr);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff15;
}
/* footerCopyWrite */
.footerCopyWrite {
  margin: 20px 0 0;
  border-top: 1px solid #ffffff3d;
}
.copyWriteText {
  font-size: 0.9em;
  color: var(--bgClr);
  margin-top: 20px;
}
.copyWriteText span {
  border-bottom: 1px solid var(--redClr);
  padding-bottom: 3px;
}

@media screen and (min-width: 768px) {
  .footerContainer {
    padding: 0 0 20px;
  }
  .footerGrid {
    grid-template-columns: 1.4fr 1fr 1fr;
    margin-top: -25px;
  }
}
@media screen and (min-width: 1100px) {
  .footerGrid {
    grid-template-columns: 1.6fr 1fr 1fr;
    margin-top: -25px;
  }
  .footerDesc {
    margin-left: 80px;
  }
}
