.addAddressForm {
  background-color: var(--bgClr);
  border-radius: 5px;
  box-shadow: 0 0 15px var(--blackOp12);
  padding: 15px;
  gap: 15px;
}
.addressFieldset {
  margin-top: -10px;
}
.profileMapHolder {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.profileMapHolder::after {
  content: "";
  width: 100%;
  height: 18px;
  background-color: var(--bgClr);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
}
.profileAddAddressTitle {
  font-size: 0.95em;
  color: var(--clr3);
  gap: 5px;
  margin-bottom: 10px;
}
