.profileOrderList {
  gap: 20px;
  margin-top: 15px;
}
.profileOrderItem {
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 5px;
  padding: 15px;
  overflow: hidden;
}
.profileOrderItemHeader {
  border-bottom: 1px solid var(--blackOp12);
  padding: 5px 0 15px;
  margin-bottom: 15px;
}
.profileOrderItemName {
  font-size: 0.95em;
  font-weight: 500;
  color: var(--clr3);
}
.profileOrderItemName::after {
  content: "";
  width: 5px;
  height: 120%;
  position: absolute;
  right: -15px;
  top: -10%;
}
.profileOrderItemName.inProgress::after {
  background-color: var(--blueClr3);
}
.profileOrderItemName.complete::after {
  background-color: var(--greenClr1);
}
.profileOrderItemName.canceled::after {
  background-color: var(--redClr);
}
.profileOrderSeeDetails {
  color: var(--blueClr2);
  font-weight: 400;
  font-size: 0.85em;
  gap: 5px;
  border-radius: 4px;
  padding: 6px 10px 6px 5px;
  transition: background-color 0.4s;
}
.profileOrderSeeDetails:hover {
  background-color: var(--blueClrOp5);
}
.profileOrderDetailsDiv {
  grid-template-columns: 80px 1fr;
  gap: 15px;
  align-items: center;
}
.profileOrderItemImg {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.profileOrderInfoDiv {
  gap: 5px;
}
.profileOrderState,
.profileOrderPrice,
.profileOrderTrackingCode,
.profileOrderText {
  color: var(--clr3);
  font-size: 0.95em;
  font-weight: 500;
  gap: 8px;
}
.profileOrderState svg,
.profileOrderPrice svg,
.profileOrderTrackingCode svg,
.profileOrderText svg {
  color: var(--clr7);
}
.profileOrderSpanTitle {
  font-size: 0.9em;
  color: var(--clr7);
  margin: 0 2px 0 2px;
}
.stateIcon {
  position: absolute;
  left: 0;
  bottom: -10px;
  height: 100px;
  width: 100px;
  opacity: 0.08;
}
@media screen and (min-width: 968px) {
  .profileOrderList {
    margin-top: 0;
  }
  .profileOrderDetailsDiv {
    grid-template-columns: 150px 1fr;
    gap: 15px;
    align-items: flex-start;
  }
  .profileOrderState svg,
  .profileOrderPrice svg,
  .profileOrderTrackingCode svg,
  .profileOrderText svg {
    height: 20px;
    width: 20px;
  }
  .stateIcon {
    left: 0;
    bottom: -10px;
    height: 125px;
    width: 125px;
  }
}
/* ProfileOrderDetails */
.profileOrderDetailsMainDiv {
  margin-top: 5px;
  gap: 10px;
}
.profileBaseInfoDiv,
.profileOrderItemsDiv {
  background-color: var(--bgClr);
  border-radius: 5px;
  box-shadow: 0 0 15px var(--blackOp12);
  padding: 15px;
  gap: 3px;
}
.orderName,
.profileOrderListTitle {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--clr3);
  margin-bottom: 5px;
}
.orderName {
  border-bottom: 1px solid var(--blackOp12);
  padding: 0 0 7px;
  margin-bottom: 8px;
}
.borderDiv {
  width: 100%;
  height: 1px;
  background-color: var(--blackOp12);
  margin: 10px 0;
}
.profileOrderItemList {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid var(--blackOp20);
  margin-top: 5px;
}
.profileOrderDetailsItem,
.profileOrderItemTitleDiv {
  border-bottom: 1px solid var(--blackOp20);
  padding: 10px;
  grid-template-columns: 30px 90px 1fr;
  gap: 5px;
}
.profileOrderDetailsItem:last-child {
  border-bottom: none;
}
.profileOrderDetailsItem:nth-child(odd) {
  background-color: var(--blackOp06);
}
.profileOrderDetailsText,
.profileOrderDetailsCounter {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr5);
  gap: 5px;
}
.profileOrderDetailsCounter {
  padding-left: 5px;
  text-align: center;
}
.profileOrderDetailsItemImg {
  border-radius: 5px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.profileOrderDetailsInfoDiv {
  margin-right: 10px;
}
.profileOrderItemTitleDiv {
  display: none;
}
a.profileOrderNameDetails {
  transition: border-bottom 0.4s;
  border-bottom: 1px solid transparent;
  width: fit-content;
  height: fit-content;
  align-self: center;
}
a.profileOrderNameDetails:hover {
  border-bottom: 1px solid var(--blackOp44);
}
@media screen and (min-width: 768px) {
  .profileOrderItemTitleDiv {
    display: -ms-grid;
    display: -moz-grid;
    display: grid;
  }
  .profileOrderItemTitleDiv {
    grid-template-columns: 40px 80px 3fr 1fr 1fr 1fr 1fr;
    background-color: var(--blackOp06);
  }
  .profileOrderDetailsItem {
    grid-template-columns: 40px 80px 1fr;
    gap: 5px;
  }
  .profileOrderDetailsInfoDiv {
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    margin-right: 0;
  }
  .profileOrderDetailsText {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profileOrderDetailsText .orderTitleSpan {
    display: none;
  }
  .profileOrderNameDetails {
    justify-content: flex-start;
    margin-right: 10px;
  }
}
@media screen and (min-width: 968px) {
  .profileOrderItemTitleDiv {
    grid-template-columns: 40px 40px 3fr 1fr 1fr 1fr 1fr;
    background-color: var(--blackOp06);
  }
  .profileOrderDetailsItem {
    grid-template-columns: 40px 40px 1fr;
    gap: 5px;
  }
}
@media screen and (min-width: 1100px) {
  .profileOrderItemTitleDiv {
    grid-template-columns: 40px 80px 3fr 1fr 1fr 1fr 1fr;
    background-color: var(--blackOp06);
  }
  .profileOrderDetailsItem {
    grid-template-columns: 40px 80px 1fr;
    gap: 5px;
  }
  .profileOrderDetailsText {
    font-size: 0.95em;
  }
  .profileOrderNameDetails {
    justify-content: flex-start;
    padding-right: 15px;
  }
}
