.mapSection {
  height: 100dvh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1200;
  background-color: var(--bgClr);
}
.leaflet-container {
  width: 100%;
  height: 100%;
}
.leaflet-top {
  margin: 75px 0 0 10px !important;
}
.mapHeader {
  height: 50px;
  width: 90%;
  max-width: 850px;
  margin: 0 auto;
  position: absolute;
  z-index: 1210;
  background-color: var(--bgClr);
  border-radius: 40px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 15px var(--blackOp44);
  padding: 0 20px;
}
.mapSection .profileSubLoading {
  z-index: 1200;
  height: 100dvh;
  background-color: var(--bgClr);
}
.popUpSchoolDiv {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  padding: 15px;
  background-color: var(--bgClr);
  border-radius: 7px;
  box-shadow: 0 0 15px var(--blackOp44);
  z-index: 1250;
  width: 90%;
  max-width: 400px;
  animation: showPopSchool 0.2s linear;
}
@keyframes showPopSchool {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
.popUpSchoolContent {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}
.popUpSchoolImg {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1.2;
  border-radius: 7px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.popUpSchoolInfoDiv {
  padding: 15px 0;
}
.seeSchoolLink {
  margin: auto auto 0 0;
  color: var(--blueClr1);
}
.centerItem {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--blueClr1);
  z-index: 1100;
  pointer-events: none;
  opacity: 0.8;
}
.centerItem::after {
  content: "";
  width: 20px;
  height: 3px;
  background-color: var(--blueClr1);
  position: absolute;
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
}
.centerItem svg {
  height: 40px;
  width: 40px;
}
