.teachersSection {
  background-color: var(--blackOp07);
  padding: 40px 0;
  margin-top: 30px;
}
.teacherSwiper {
  width: 100%;
  margin: 20px 0 0;
}
.teacherItemDiv {
  padding: 30px 0;
  grid-template-columns: 120px 1fr;
  gap: 20px;
}
.teacherItemImg {
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.teacherImgLink {
  width: 120px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
}
.teachersSection .goToNextSlide,
.teachersSection .goToPreSlide {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  background-color: #eee;
  z-index: 10;
  cursor: pointer;
  border-radius: 3px;
}
.teachersSection .goToPreSlide {
  left: 5px;
  right: unset;
}
