/* commentStarMainDiv */
.commentStarMainDiv {
  height: 20px;
  width: 100px;
  background-color: var(--clrE);
}
.commentStarBg {
  background-color: var(--blueClr3);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 5;
}
.commentStarImg {
  width: 100px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
}
/* commentSection */
.commentSection {
  gap: 20px;
  margin-top: 40px;
}
.commentSummaryHeader {
  padding: 5px 0 15px;
  border-bottom: 1px solid var(--blackOp20);
}
.commentSummaryTitle {
  gap: 5px;
  font-size: 0.9em;
}
.commentSummary {
  padding: 20px;
  border: 1px dashed var(--blackOp20);
  border-radius: 12px;
}
.commentSummaryTitle span {
  border-bottom: 2px solid transparent;
}
.summaryTitleTotalRate {
  padding: 0 5px;
  border-bottom: 2px solid var(--blueClr3) !important;
  color: var(--clr3);
}
.insertCommentBtn {
  height: 45px;
  width: 100%;
  background-color: var(--mainClr1);
  border-radius: 7px;
  margin-top: 15px;
  color: var(--bgClr);
  gap: 5px;
}
.commentsList {
  margin-top: 10px;
  gap: 15px;
}
.commentItem {
  background-color: var(--bgClr);
  border-radius: 10px;
  box-shadow: 0 0 15px var(--blackOp12);
  padding: 20px;
}
.commentItemHeaderDiv {
  padding: 10px 0 20px;
  border-bottom: 1px dashed var(--blackOp20);
}
.commentUserBody {
  margin-top: 20px;
  text-align: justify;
}
.commentAdminAnswer {
  margin: 25px 50px 0 0;
  text-align: justify;
  border: 1px dashed var(--blackOp20);
  padding: 15px;
  border-radius: 10px;
  gap: 5px;
  background-color: var(--blackOp06);
  line-height: 1.5em;
  position: relative;
}
.commentAdminAnswer::before {
  content: "";
  width: 50px;
  height: 50%;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-120%);
  border-bottom: 1px dashed var(--blackOp20);
  border-right: 1px dashed var(--blackOp20);
  border-radius: 0 0 10px 0;
}
@media screen and (min-width: 768px) {
  .commentSection {
    grid-template-columns: 1fr 300px;
    gap: 20px;
  }
  .commentSummary {
    order: 2;
    height: fit-content;
    position: sticky;
    top: 60px;
    transform: translateY(40px);
    margin-bottom: 100px;
  }
  .commentSummaryHeader {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .commentSummaryHeader .commentStarMainDiv {
    margin: 0 auto 0 0;
  }
}
@media screen and (min-width: 1100px) {
  .commentSection {
    grid-template-columns: 1fr 350px;
    gap: 20px;
  }
  .commentSummary {
    order: 2;
    height: fit-content;
    position: sticky;
    top: 60px;
    transform: translateY(40px);
    margin-bottom: 100px;
  }
  .commentSummaryHeader {
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .commentSummaryHeader .commentStarMainDiv {
    margin: 0;
  }
}
/* insert comment */
.closeInsertCommentDiv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background-color: var(--blackOpD2);
  z-index: 990;
  opacity: 0.8;
  backdrop-filter: blur(10px);
  display: none;
}
.closeInsertCommentDiv.active {
  display: block;
}
.insertCommentForm {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 550px;
  background-color: var(--bgClr);
  border-radius: 7px;
  z-index: 991;
  padding: 20px;
  overflow: hidden;
  display: none;
}
.insertCommentForm.active {
  display: -webkit-flex;
  display: flex;
}
.closeInsertCommentBtn {
  width: 30px;
  height: 30px;
  border: 1px solid var(--clrA);
  border-radius: 7px;
  color: var(--clr5);
}
.insertCommentTextArea {
  border-radius: 7px;
  outline: none;
  border: 1px solid var(--blackOp44);
  padding: 7px;
  margin-top: 25px;
  resize: none;
  height: 180px;
}
.commentRateDiv {
  margin-top: 10px;
  gap: 10px;
  align-items: center;
}
.insertCommentRateDiv {
  width: 100px;
  height: 20px;
  background-color: var(--blackOp20);
}
.insertCommentRateProgress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--blueClr3);
  z-index: 4;
}
.insertCommentRateImg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.insertCommentRateInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  opacity: 0;
  direction: ltr;
  cursor: pointer;
}
.submitInsertComment {
  background-color: var(--blueClr2);
  height: 40px;
  width: 100%;
  border-radius: 7px;
  margin-top: 20px;
}
.loadingInsertComment {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffffda;
  height: 100%;
  width: 100%;
  z-index: 20;
  gap: 16px;
}
.loadingInsertCommentProgress {
  width: 20px;
  height: 20px;
  border-left: 3px solid var(--blackOp44);
  border-top: 3px solid var(--blackOp44);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  margin-top: -3px;
}
/* noComment */
.noComment {
  padding: 40px 0;
  background-color: var(--blackOp06);
  border-radius: 7px;
}
.noCommentIcon {
  height: 200px;
  width: 200px;
  color: var(--blackOp12);
}
.noCommentTitle {
  font-size: 1.4em;
  margin: 10px 0 5px;
}
