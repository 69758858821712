.allCastsLoading {
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.allCastsLoadingItem {
  background-color: var(--blackOp06);
  border-radius: 7px;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1/1.2;
}
/* podcastGrid */
.podcastGrid {
  grid-template-columns: 1fr;
  gap: 15px;
}
@media screen and (min-width: 380px) {
  .podcastGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 649px) {
  .podcastGrid,
  .allCastsLoading {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 968px) {
  .podcastGrid,
  .allCastsLoading {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
