.contactGrid {
  margin-top: 20px;
}
.contactForm {
  gap: 20px;
  padding: 0 15px;
  margin: 40px auto 0;
  max-width: 550px;
  width: 100%;
}
fieldset {
  outline: none;
  border: none;
  gap: 3px;
}
.categorySelect,
.contactCustomInput {
  outline: none;
  border-radius: 5px;
  border: 1px solid var(--blackOp20);
  height: 45px;
  padding: 0 7px;
  background-color: transparent;
  transition: border 0.4s;
}
.contactCustomTextArea {
  height: 180px;
  padding: 7px;
  border: 1px solid var(--blackOp20);
  border-radius: 5px;
  background-color: transparent;
  resize: none;
  overflow-y: auto;
}
.contactCustomInput:focus,
.categorySelect:focus,
.contactCustomTextArea:focus {
  border: 1px solid var(--blueClr2);
}
.contactSubmitBtn {
  background-color: var(--blueClr1);
  height: 45px;
  border-radius: 5px;
  color: var(--bgClr);
}
.contactLeftDiv {
  display: none;
}
@media screen and (min-width: 968px) {
  .contactGrid {
    grid-template-columns: 1fr 380px;
    align-items: center;
    gap: 50px;
    padding: 0 20px;
  }
  .contactLeftDiv {
    display: -webkit-flex;
    display: flex;
  }
  .contactLeftImg {
    width: 100%;
    max-width: 450px;
  }
  .contactForm {
    padding: 0;
  }
}
@media screen and (min-width: 1100px) {
  .contactGrid {
    grid-template-columns: 1fr 1fr;
  }
  .contactLeftImg {
    margin-right: 50px;
  }
}
