.courseSection {
  margin: 40px auto 0;
}
.sectionHeader {
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--blackOp09);
}
.sectionTitle {
  gap: 7px;
  font-size: 1em;
}
.sectionTitle svg {
  height: 20px;
  width: 20px;
  color: var(--clr5);
}
.sectionLink {
  color: var(--blueClr1);
  font-size: 0.8em;
}
@media screen and (min-width: 768px) {
  .sectionHeader {
    padding: 25px 25px;
  }
  .sectionTitle svg {
    height: 30px;
    width: 30px;
    color: var(--clr5);
  }
  .sectionTitle {
    gap: 10px;
    font-size: 1.2em;
  }
  .sectionLink {
    font-size: 0.95em;
    position: relative;
  }
  .sectionLink::after {
    content: "";
    width: 0;
    right: 0;
    bottom: -3px;
    background-color: var(--blueClr1);
    position: absolute;
    height: 1px;
    transition: width 0.4s;
  }
  .sectionLink:hover::after {
    width: 100%;
  }
}
/* latestVideoSection */
.latestVideoSection {
  margin-top: 20px;
}
/* homeBlogSection */
.homeBlogSection {
  margin-top: 40px;
}
.blogTitle1 {
  font-size: 0.8em;
  margin-right: 40px;
  position: relative;
  color: var(--blueClr2);
}
.blogTitle1::before {
  content: "";
  width: 60vw;
  height: 2px;
  position: absolute;
  right: -10px;
  top: 50%;
  background-color: var(--blueClr2);
  transform: translate(100%, -50%);
}
.homeBlogHeader {
  margin-top: 10px;
  padding: 0 20px;
}
.blogTitle2 {
  font-size: 1.35em;
  color: var(--clr5);
}
.seeAllBlogs {
  border-radius: 5px;
  padding: 4px 15px 4px 10px;
  border: 1px solid var(--clr7);
  color: var(--clr5);
}
.blogSwiper {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 80px;
}
@media screen and (min-width: 649px) {
  .blogSwiper {
    padding: 0 15px 60px;
  }
  .blogTitle1 {
    font-size: 1.2em;
  }
  .blogTitle2 {
    font-size: 1.4em;
  }
}
/* homeAboutSection */
.homeAboutSection {
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: -20px;
}
.aboutSectionRightDiv .contentDescDiv {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
.aboutSectionRightDiv .contentList {
  max-height: 250px;
}
.homeAboutImg {
  width: 100%;
  border-radius: 5px;
}
.aboutSectionRightDiv {
  background-color: var(--bgClr);
  order: 2;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 15px var(--blackOp44);
  margin: -90px 20px 0;
}
.aboutSectionRightDiv .showFullDescription {
  bottom: -10px;
}
@media screen and (min-width: 649px) {
  .homeAboutSection {
    grid-template-columns: 1fr 1.5fr;
  }
  .aboutSectionRightDiv {
    margin: 90px 0 20px;
    width: 180%;
    z-index: 10;
  }
  .homeAboutLeftDiv {
    order: 2;
    height: fit-content;
    position: sticky;
    top: 100px;
  }
}
@media screen and (min-width: 1150px) {
  .homeAboutSection {
    grid-template-columns: 1fr 1.2fr;
    padding: 0 5%;
    margin-top: 10px;
  }
  .aboutSectionRightDiv {
    width: 150%;
    margin: 90px 60px 20px 0;
  }
}
/* .homeBlogsGrid {
  margin: 30px auto 0;
  gap: 25px;
}
.homeBlogItem {
  grid-template-columns: 1fr;
  gap: 10px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.homeBlogImgLink {
  width: 100%;
}
.homeBlogImg {
  width: 100%;
  aspect-ratio: 2/0.8;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.homeBlogInfoDiv {
  padding: 15px 0 15px 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #000, #00000000);
  width: 100%;
  padding: 80px 15px 10px 15px;
  pointer-events: none;
}
.homeBlogName {
  color: var(--bgClr);
}
.homeBlogBody {
  color: #ccc;
}
@media screen and (min-width: 768px) {
  .homeBlogsGrid {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .homeBlogImg {
    width: 100%;
    aspect-ratio: 2/1;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}
@media screen and (min-width: 1100px) {
  .homeBlogsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
  }
} */
