.playerSection {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  left: 0;
  bottom: 0;
  background-color: var(--bgClr);
  animation: toTop 0.4s linear;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
  transform: translateY(250%);
  transition: transform 0.4s, opacity 0s 0.4s;
  pointer-events: none;
  opacity: 0;
  min-width: 320px;
  padding: 80px 10% 0;
}
.playerSection.show {
  pointer-events: unset;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.4s;
}
.playerDiv {
  padding: 20px;
  border-radius: 5px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp10);
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 10px;
}
.musicInfoImg {
  width: 90%;
  max-width: 180px;
  border-radius: 5px;
  margin: 0 auto;
}
.podcastTitle {
  margin-top: 20px;
}
.podcastDesc {
  color: var(--clr7);
  text-align: justify;
  margin-bottom: 30px;
}
.playerAudio {
  width: 100%;
  height: 50px;
  border-radius: 0;
  background-color: transparent;
  margin-top: 10px;
}
.podcastBtnMainDiv {
  flex-wrap: wrap;
  gap: 15px;
}
.podcastTimeMainDiv {
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.podcastProgress {
  height: 10px;
  cursor: pointer;
  direction: ltr;
  width: 100%;
  margin: 20px auto 0;
  border-radius: 10px;
  position: relative;
  justify-content: center;
}
.podcastProgress::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--blackOp20);
  z-index: 5;
  border-radius: 7px;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.podcastProgressBar {
  width: 0;
  height: 5px;
  pointer-events: none;
  background-color: var(--blueClr2);
  transition: width 0.4s;
  border-radius: 10px;
  position: relative;
  z-index: 6;
}
.podcastProgressBar::after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--blueClr2);
  border-radius: 50%;
  transform: translate(50%, -36%);
  cursor: pointer;
  z-index: 7;
}
.passedTime {
  color: var(--clr3);
  font-size: 0.85em;
  width: 45%;
  text-align: left;
  white-space: nowrap;
  margin-right: auto;
}
.padCastTotalTime {
  color: var(--clrA);
  font-size: 0.74em;
  width: 45%;
  text-align: right;
  white-space: nowrap;
}
.playBtnDiv {
  width: 100%;
}
.playBtn {
  width: 50px;
  height: 50px;
  background-color: var(--blackOp09);
  font-size: 0.95em;
  border-radius: 50%;
}
.playBtn > .pauseSvg {
  display: none;
}
.play > .playSvg {
  display: none;
}
.play > .pauseSvg {
  display: -webkit-flex;
  display: flex;
}
.forwardBtn,
.backwardBtn {
  width: 38px;
  height: 38px;
  font-size: 1.7em;
  color: var(--clr3);
}
.musicPlayerCloser {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  right: 30px;
  top: 30px;
  box-shadow: 0 0 5px 4px var(--blackOp10);
  color: var(--clr3);
  font-size: 1.2em;
}
.volumeMainDiv {
  width: 100%;
}
.nextMusicBtn,
.prevMusicBtn,
.speakerIcon,
.repeateMusicBtn {
  width: 38px;
  height: 38px;
  font-size: 1.1em;
  color: var(--clr7);
}
.speakerIcon {
  font-size: 1.25em;
}
.repeateMusicBtn {
  font-size: 1.2em;
}
.repeateMusicBtn.repeatOn {
  color: var(--blueClr2);
}
.volumeDiv {
  width: 100px;
  height: 9px;
  background-color: var(--blackOp12);
  direction: ltr;
  margin-left: 5px;
}
.volumeProgress {
  width: 100%;
  height: 100%;
  background-color: var(--clr7);
  pointer-events: none;
  transition: width 0.4s;
}
@media screen and (min-width: 769px) {
  .playerSection {
    height: fit-content;
    padding: 20px 15px;
    box-shadow: 0 -5px 15px var(--blackOp12);
  }
  .musicInfoImg,
  .podcastTitle,
  .podcastDesc {
    display: none;
  }
  .podcastBtnMainDiv {
    flex-wrap: nowrap;
  }
  .podcastTimeMainDiv {
    order: 2;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    width: 60%;
  }
  .podcastProgress {
    width: 100%;
    order: 2;
    margin-top: 0;
  }
  .passedTime {
    order: 3;
    width: 70px;
    text-align: center;
  }
  .padCastTotalTime {
    order: 1;
    width: 70px;
    text-align: center;
  }
  .playBtnDiv {
    order: 3;
    width: 150px;
    gap: 10px;
  }
  .volumeMainDiv {
    order: 1;
    width: 30%;
    justify-content: flex-end;
    padding-left: 40px;
  }
  .playBtn {
    width: 30px;
    height: 30px;
  }
  .musicPlayerCloser {
    position: unset;
    transform: none;
    right: unset;
    top: unset;
    margin-left: auto;
  }
  .podcastProgressBar::after {
    width: 12px;
    height: 12px;
    transform: translate(50%, -32%);
  }
}
@keyframes toTop {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
