.courseCatTitleDiv {
  margin-top: 25px;
}
.courseCatTitle {
  border-bottom: 2px solid var(--blackOp12);
  width: fit-content;
  padding: 5px 0 5px 5px;
  color: var(--clr5);
  font-size: 0.85em;
  gap: 7px;
}
.courseCatTitle svg {
  color: var(--blueClr2);
}
.courseSwiper {
  width: 100%;
  margin-top: 10px;
  padding: 5px 0 10px;
}
@media screen and (min-width: 768px) {
  .courseCatTitle {
    font-size: 0.95em;
  }
}
