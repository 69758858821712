.blogItemDiv {
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0px 15px 25px 0px rgba(33, 37, 43, 0.1);
}
.blogItemImg {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  aspect-ratio: 2/1.2;
}
.blogInfoDiv {
  background-color: var(--bgClr);
  padding: 20px 15px 20px;
  gap: 5px;
  height: 100%;
}
.blogItemBody {
  font-size: 0.9em;
}
.seeBlogLink {
  margin: 15px auto 0 0;
  font-size: 0.8em;
  gap: 5px;
  color: var(--blueClr2);
}
.seeBlogLink {
  margin-top: auto;
}
