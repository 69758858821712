.courseFilterLoading {
  grid-template-columns: 1fr 40px;
  gap: 5px;
}
.filterLoadingBtn {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background-color: var(--blackOp07);
  overflow: hidden;
  position: relative;
}
.searchInputLoading {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--blackOp07);
  overflow: hidden;
  position: relative;
}
.resultCourseLoadingGrid {
  gap: 20px;
}
.resultCourseLoadingItem {
  width: 100%;
  border-radius: 7px;
  background-color: var(--blackOp07);
  overflow: hidden;
  aspect-ratio: 1.4/1;
}
/* mainGrid */
.mainGrid {
  margin-top: 10px;
  gap: 20px;
}
.mainGridRightDiv {
  align-items: center;
  grid-template-columns: 1fr 45px;
  gap: 5px;
  position: relative;
}
.rightSideSearchDiv {
  height: 45px;
  border-radius: 5px;
  background-color: var(--blackOp07);
}
.courseSearchInput {
  outline: none;
  border: none;
  height: 45px;
  padding: 0 15px 0 50px;
  background-color: transparent;
}
.courseSeachSubmitBtn {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 15px;
  border-radius: 5px;
  height: 45px;
  width: 45px;
}
.courseSeachSubmitBtn .searchIcon {
  height: 18px;
  width: 18px;
  color: var(--clr5);
}
.activeCatBtn {
  height: 45px;
  width: 45px;
  background-color: var(--blackOp07);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.activeCatBtn * {
  pointer-events: none;
}
.activeCatText {
  display: none;
}
.activeCatBtn .arrowIcon {
  display: none;
}
.allCatsList {
  position: absolute;
  padding: 15px;
  background-color: var(--bgClr);
  box-shadow: 0 10px 5px var(--blackOp12);
  border-radius: 7px;
  z-index: 300;
  width: 100%;
  top: 55px;
  display: none;
  max-height: 250px;
  overflow-y: auto;
  left: 0;
}
.allCatsList.active {
  display: -webkit-flex;
  display: flex;
}
.catItemLink {
  padding: 15px 10px;
  border-bottom: 1px solid var(--blackOp12);
  transition: background-color 0.4s;
  cursor: pointer;
  gap: 15px;
  align-items: flex-start;
}
.catItemLink:hover {
  background-color: var(--blackOp07);
}
.catsListItem:last-child .catItemLink {
  border-bottom: none;
}
.checkBoxDiv {
  width: 17px;
  min-width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid var(--blueClr3);
  position: relative;
  margin-top: 2px;
}
.checkCircle {
  width: 9px;
  height: 9px;
  opacity: 0;
  transition: opacity 0.4s;
  color: var(--blueClr3);
}
.catItemLink:hover .checkCircle {
  opacity: 0.4;
}
.catItemLink.activeCat .checkCircle {
  opacity: 1;
}
.activeCatBtn svg {
  transition: transform 0.4s;
}
.activeCatBtn .activeIcon {
  transform: rotate(180deg);
}
.activeCatBtn .filterIcon {
  width: 15px;
  height: 15px;
  color: var(--clr5);
}
.courseGrid {
  gap: 20px;
}
.courseGrid .courseItemMainDiv {
  padding: 0;
  margin: 0;
}
.loadingMoreData {
  position: fixed;
  left: 50%;
  top: 60%;
  padding: 60px;
  transform: translate(-50%, -50%);
  z-index: 800;
}
.loadingMoreDataSubDiv {
  background-color: #00000041;
  padding: 40px 20px;
  border-radius: 7px;
  width: 94%;
  max-width: 250px;
  margin: 0 auto;
}
.loadingMoreDataSubDiv svg {
  color: var(--bgClr);
  height: 40px;
  width: 40px;
  animation: rotate 2s linear infinite reverse;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 649px) {
  .courseGrid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .resultCourseLoadingGrid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .courseFilterLoading {
    grid-template-columns: 1fr;
    height: fit-content;
    gap: 15px;
  }
  .filterLoadingBtn {
    width: 100%;
    height: unset;
    aspect-ratio: 1/1.1;
  }
  .searchInputLoading {
    width: 100%;
  }
}
@media screen and (min-width: 968px) {
  .mainGrid {
    grid-template-columns: 280px 1fr;
  }
  .mainGridRightDiv {
    grid-template-columns: 1fr;
    height: fit-content;
    position: sticky;
    top: 100px;
    gap: 15px;
  }
  .activeCatBtn {
    width: 100%;
    display: none;
  }
  .allCatsList {
    position: unset;
    left: unset;
    top: unset;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    max-height: 60dvh;
    overflow-y: auto;
    box-shadow: 0 5px 25px var(--blackOp12);
  }
  .rightSideSearchDiv {
    background-color: var(--bgClr);
    box-shadow: 0 5px 25px var(--blackOp12);
  }
  .catItemLink {
    font-size: 0.9em;
    padding: 12px 10px;
  }
}
@media screen and (min-width: 1150px) {
  .courseGrid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
  .resultCourseLoadingGrid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
}
