.videoItemDiv {
  border: 1px solid var(--blackOp07);
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 0 15px var(--blackOp20);
  margin-bottom: 10px;
}
.videoParentItem {
  border-radius: 7px;
  overflow: hidden;
}
.videoItemVideo,
.videoImg {
  aspect-ratio: 2/1.2;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.playLogo {
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ffffff3b;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.playLogo svg {
  color: #00000083;
  width: 25px;
  height: 25px;
}
.videoInfoDiv {
  padding: 15px 65px 5px 15px;
}
.teacherImg {
  position: absolute;
  right: 10px;
  top: 15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.teacherName {
  width: fit-content;
}
.videoItemFooter {
  padding: 15px 0 10px;
  border-top: 1px solid var(--blackOp20);
  margin: 10px 15px 0;
}
.videoProgressHolder {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 6px;
  background-color: #ffffff80;
}
.videoProgress {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  width: 0;
  background-color: var(--blueClr1);
  opacity: 1;
  transition: width 0.4s;
}
.itemPriceDiv {
  justify-content: flex-end;
  margin-top: 20px;
}
.videoItemDiscount {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 6px;
  background-color: var(--redClr);
  z-index: 40;
  padding: 1px 7px;
  color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp20);
  gap: 3px;
  font-size: 0.85em;
}
.videoItemDiscount svg {
  font-size: 0.9em;
  transform: translateY(-1px);
}
