.schoolInfoSection {
  margin-top: 15px;
  gap: 30px;
  background-color: var(--bgClr);
  border-radius: 7px;
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 0 15px var(--blackOp12);
}
.schoolRightDiv {
  gap: 20px;
}
.schoolTitle {
  font-weight: 600;
}
.schoolInfoSection .contentList {
  margin-top: 15px;
}
.schoolInfoSection .contentDescDiv {
  box-shadow: none;
  padding: 0;
}
.schoolMiddleDiv {
  flex-direction: column;
}
.schoolLeftMiddleDiv {
  background-color: var(--bgClr);
  border-radius: 7px;
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 0 15px var(--blackOp12);
  gap: 10px;
}
.schoolInfoItem {
  padding: 10px 0 20px;
  border-bottom: 1px solid var(--blackOp12);
  gap: 7px;
  align-items: flex-start;
}
.schoolInfoTitleText {
  gap: 5px;
}
.schoolInfoItem svg {
  width: 30px;
  height: 18px;
  color: var(--blueClr1);
}
.schoolInfoItem:last-child {
  border-bottom: none;
  padding-bottom: 10px;
}
.schoolInfoDescText {
  padding-right: 35px;
}
.schoolRightMiddleDiv .teacherCourseSection {
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .schoolInfoSection {
    grid-template-columns: 1fr 250px;
  }
  .schoolInfoSection .courseFirstLeftDiv {
    height: fit-content;
    margin-top: 45px;
    position: sticky;
    top: 100px;
  }
}
@media screen and (min-width: 968px) {
  .schoolInfoSection {
    grid-template-columns: 1fr 350px;
    gap: 40px;
    padding: 20px;
  }
  .schoolMiddleDiv {
    flex-direction: row;
    gap: 20px;
  }
  .schoolLeftMiddleDiv {
    width: 250px;
    height: fit-content;
    position: sticky;
    top: 100px;
  }
  .schoolRightMiddleDiv {
    width: calc(100% - 270px);
  }
  .schoolRightMiddleDiv .teacherCourseSection {
    padding: 0;
  }
}
@media screen and (min-width: 1100px) {
  .schoolInfoSection {
    grid-template-columns: 1fr 400px;
  }
}
