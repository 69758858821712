.audioBookInfoSection {
  margin-top: 20px;
  gap: 20px;
}
.audioBookInfoDiv {
  gap: 5px;
}
.audioPlayActionDiv {
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 40px 60px;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}
.audioDurationDiv {
  width: 100%;
  flex-wrap: wrap;
  gap: 20px 10px;
  cursor: pointer;
}
.audioProgressDiv {
  height: 10px;
  width: 100%;
  background-color: var(--blackOp20);
}
.audioProgressItem {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  top: 0;
  background-color: var(--clr7);
  transition: width 0.2s;
}
.nextAudioBtn,
.prevAudioBtn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.audioPlayBtn {
  background-color: var(--blackOp07);
  border-radius: 50%;
  height: 70px;
  width: 70px;
}
.audioPlayListDiv {
  margin-top: 40px;
  border-top: 1px solid var(--blackOp20);
  padding-top: 40px;
}
.audioPlayList {
  gap: 40px;
  background-color: var(--blackOp06);
  padding: 15px;
  margin-top: 30px;
  border-radius: 7px;
}
.audioPlayItem {
  grid-template-columns: 80px 1fr 40px 40px;
  gap: 20px;
  position: relative;
  align-items: center;
  padding: 0 5px;
}
.audioPlayItem::after {
  content: "";
  width: 96%;
  height: 1px;
  background-color: var(--blackOp20);
  opacity: 0.6;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}
.audioPlayItem:last-child::after {
  display: none;
}
.audioItemImg {
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.audioPlayItemPlayBtn,
.audioPlayItemDownloadBtn {
  width: 100%;
  aspect-ratio: 1/1;
  color: var(--clr3);
  border-radius: 3px;
}
@media screen and (min-width: 768px) {
  .audioBookInfoSection {
    grid-template-columns: 280px 1fr;
    gap: 30px;
  }
  .audioImg {
    position: sticky;
    top: 100px;
  }
}
@media screen and (min-width: 968px) {
  .audioBookInfoSection {
    grid-template-columns: 320px 1fr;
    gap: 40px;
  }
  .audioPlayItem {
    grid-template-columns: 100px 1fr 40px 40px;
  }
}
@media screen and (min-width: 1100px) {
  .audioPlayActionDiv {
    margin-top: 60px;
    padding: 0 10px;
    gap: 30px;
    justify-content: flex-end;
  }
  .audioPlayDiv {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .audioDurationDiv {
    width: calc(100% - 350px);
    order: 3;
    margin-top: 20px;
  }
  .prevAudioBtn {
    order: 5;
    transform: translateY(-10px);
    height: 60px;
    width: 60px;
  }
  .nextAudioBtn {
    order: 2;
    transform: translateY(-10px);
    height: 60px;
    width: 60px;
  }
  .audioPlayBtn {
    transform: translateY(-10px);
    background-color: var(--blackOp06);
    height: 60px;
    width: 60px;
    margin-left: auto;
  }
}
