.middleBannerLink {
  margin-top: 20px;
}
.middleBannerImg,
.mobileMiddleBanner {
  width: 100%;
  height: auto;
}
.middleBannerImg {
  display: none;
}
@media screen and (min-width: 768px) {
  .mobileMiddleBanner {
    display: none;
  }
  .middleBannerImg {
    display: block;
  }
}
