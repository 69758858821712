.teachersList {
  gap: 15px;
}
.teacherPageItem {
  background-color: var(--bgClr);
  padding: 20px;
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 5px;
  transition: box-shadow 0.4s;
}
.teacherPageItem:hover {
  box-shadow: 0 0 20px var(--blackOp20);
}
.teacherPageImg {
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 7px;
}
.teacherPageDesc {
  font-size: 0.75em;
  color: var(--clr7);
}
.teacherPageName {
  margin-top: 10px;
  color: var(--clr3);
  font-weight: 600;
  font-size: 1.1em;
  gap: 5px;
}
.teacherPageName svg {
  color: var(--clr5);
}
.teacherPageDesc {
  position: relative;
  padding-right: 19px;
}
.teacherPageDesc::after {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--blueClr3);
  position: absolute;
  right: 3px;
  top: 6px;
}
@media screen and (min-width: 500px) {
  .teachersList {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1100px) {
  .teachersList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/* teacherMainGrid */
.teacherMainGrid {
  margin-top: 10px;
}
.topTeacherSection {
  gap: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  flex-direction: column;
}
.teacherImgDiv {
  padding: 10px;
  width: fit-content;
  background-color: var(--bgClr);
  border-radius: 50%;
  box-shadow: 0 0 15px var(--bgClr);
  margin: 0 auto;
}
.teacherInfoSection .teacherDetailsImg {
  aspect-ratio: 1/1;
  height: 125px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: fit-content;
}
.teacherDetailsName {
  text-align: center;
  font-size: 1.35em;
  font-weight: 600;
}
.teacherJobText {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  color: var(--blueClr2);
}
.teacherSummGrid {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-top: 1px solid var(--blackOp12);
  margin-top: 10px;
  padding-top: 20px;
}
.teacherSummItem {
  padding: 15px 0;
}
.teacherSummItem svg {
  display: none;
}
.summNumber {
  z-index: 10;
  font-weight: 600;
}
.summText {
  z-index: 10;
}
.teacherVideo {
  width: 100%;
  aspect-ratio: 2/1.1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.teacherVideoDiv {
  background-color: var(--bgClr);
  border-radius: 10px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  overflow: hidden;
}
.teacherSectionLeftDiv {
  height: fit-content;
  margin-top: 15px;
}
.teacherReserverDiv {
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 7px;
  padding: 20px;
}
.reserveTitle {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--clr3);
}
.reserveText {
  font-size: 0.95em;
  color: var(--clr5);
  font-weight: 400;
  margin-top: 5px;
  text-align: justify;
}
.reserveLink {
  background-color: var(--blueClr2);
  border-radius: 5px;
  color: var(--bgClr);
  width: fit-content;
  padding: 5px 12px;
  margin: 10px auto 0 0;
  font-size: 0.9em;
  font-weight: 500;
  transition: background-color 0.4s;
  position: relative;
  overflow: hidden;
}
.reserveLink::after {
  content: "";
  position: absolute;
  transform: rotate(12deg);
  height: 60px;
  width: 15px;
  left: -15px;
  top: -15px;
  opacity: 0;
  background-image: linear-gradient(to right, #ffffff0e, #fff, #ffffff0e);
  opacity: 0;
}
.reserveLink:hover::after {
  left: calc(100% + 15px);
  opacity: 0.4;
  transition: left 0.5s;
}
.teacherInfoList {
  margin-top: 15px;
  gap: 10px;
  display: none;
}
.teacherLeftInfoItem {
  font-size: 1em;
  font-weight: 500;
  color: var(--clr5);
  gap: 7px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 7px;
  padding: 20px;
}
.teacherLeftInfoItem span:first-of-type {
  margin-right: 10px;
}
@media screen and (min-width: 470px) {
  .teacherSummGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
    margin-top: 5px;
  }
}
@media screen and (min-width: 768px) {
  .teacherInfoSection {
    padding: 10px 20px 10px;
  }
  .topTeacherSection {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px 20px;
    align-items: center;
  }
  .teacherInfoSection {
    width: calc(100%);
  }
  .teacherSummGrid {
    width: calc(50% - 10px);
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
    border-top: none;
    background-color: var(--blackOp06);
    border-radius: 10px;
    gap: 0;
    aspect-ratio: 2 / 1.1;
  }
  .topTeacherSection .courseFirstLeftDiv {
    width: calc(50% - 10px);
    height: fit-content;
  }
}
@media screen and (min-width: 868px) {
  .teacherMainGrid {
    grid-template-columns: 1fr 280px;
    gap: 20px;
  }
  .teacherSummGrid {
    display: none;
  }
  .teacherSectionLeftDiv {
    margin-top: 0;
    position: sticky;
    top: -115px;
  }
  .topTeacherSection .courseFirstLeftDiv {
    width: 100%;
    margin-top: -20px;
  }
  .teacherInfoList {
    display: -webkit-flex;
    display: flex;
  }
}
@media screen and (min-width: 968px) {
  .topTeacherSection {
    gap: 30px;
  }
  .teacherInfoSection,
  .topTeacherSection .courseFirstLeftDiv {
    width: calc(100% / 2 - 20px);
  }
  .topTeacherSection .courseFirstLeftDiv {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 1100px) {
  .topTeacherSection {
    gap: 30px;
  }
  .topTeacherSection .courseFirstLeftDiv {
    margin-top: 0 !important;
  }
  .teacherSummGrid {
    grid-template-columns: 1fr 1fr;
    order: 2;
    gap: 40px 20px;
    background-color: transparent;
    padding: 40px 0;
  }
  .teacherSummItem {
    flex-direction: row;
    padding: 0;
    gap: 10px;
    font-size: 0.9em;
    align-items: center;
  }
  .summNumber {
    order: 2;
    margin-right: 20px;
  }
  .summText {
    position: relative;
  }
  .summText::after {
    content: ":";
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
/* teacherGallerySection */
.teacherGallerySection {
  margin-top: 20px;
  background-color: var(--bgClr);
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px var(--blackOp12);
}
.teacherGalleryItemDiv {
  cursor: pointer;
}
.teacherGalleryImg {
  border-radius: 7px;
  aspect-ratio: 2/1.2;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.teacherGalleryText {
  position: absolute;
  right: 7px;
  bottom: 7px;
  padding: 5px 10px 3px;
  background-color: var(--blackOp44);
  color: var(--bgClr);
  border-radius: 7px;
  pointer-events: none;
}
@media screen and (min-width: 968px) {
  .teacherGalleryImg {
    aspect-ratio: 1/0.65;
  }
}
@media screen and (min-width: 1250px) {
  .teacherGalleryImg {
    aspect-ratio: 1/0.7;
  }
}
/* aboutSection */
.aboutSection {
  margin-top: 20px;
  background-color: var(--bgClr);
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px var(--blackOp12);
}
.aboutTitle,
.teacherCourseTitle {
  color: var(--clr3);
  gap: 8px;
  padding-top: 5px;
  font-weight: 600;
}
.aboutTitle svg,
.teacherCourseTitle svg {
  color: var(--blueClr2);
  transform: translateY(-2px);
}
.aboutBodyDiv {
  position: relative;
}
.aboutMainText {
  margin-top: 5px;
  max-height: 180px;
  overflow-y: hidden;
  font-size: 0.95em;
}
.aboutBodyDiv.full .aboutMainText {
  max-height: unset;
  overflow: unset;
}
.aboutBg {
  height: 150px;
  width: 100%;
  background-image: linear-gradient(to top, #fff, #ffffffc2, #ffffff00);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
}
.aboutBg2 {
  position: absolute;
  left: 50%;
  bottom: 7px;
  height: 1px;
  width: 40%;
  background-color: var(--clr3);
  z-index: 6;
  transform: translateX(-50%);
  opacity: 0.5;
}
.aboutFullBtn {
  background-color: var(--bgClr);
  border-radius: 7px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -9px;
  z-index: 7;
  padding: 5px 15px;
  color: var(--clr3);
}
.aboutBodyDiv.full .aboutBg,
.aboutBodyDiv.full .aboutBg2,
.aboutBodyDiv.full .aboutFullBtn {
  display: none;
}
@media screen and (min-width: 649px) {
  .aboutSection {
    padding: 30px 25px;
  }
  .aboutMainText {
    font-size: 1.2em;
    margin: 7px;
  }
}
/* teacherCourseSection */
.teacherCourseSection {
  padding: 20px;
  margin-top: 20px;
}
.teacherCourseGrid {
  margin-top: 20px;
  gap: 20px;
}
.teacherCourseGrid .courseItemMainDiv {
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 649px) {
  .teacherCourseGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 968px) {
  .teacherCourseGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1250px) {
  .teacherCourseGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
/* loading */
.teacherInfoDivLoading,
.teacherGaalleryDiv {
  width: 100%;
  border-radius: 12px;
  background-color: var(--blackOp06);
  overflow: hidden;
  margin-top: 20px;
  aspect-ratio: 1/1.5;
}
.teacherGaalleryDiv {
  aspect-ratio: 2/1;
}
@media screen and (min-width: 768px) {
  .teacherInfoDivLoading,
  .teacherGaalleryDiv {
    aspect-ratio: 2/0.4;
  }
}
