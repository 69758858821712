.courseDetailsLoading {
  width: 100%;
  height: 250px;
  border-radius: 7px;
  background-color: var(--blackOp06);
  overflow: hidden;
  margin-top: 20px;
}
.courseDetailsLoading2 {
  gap: 10px;
  margin-top: 20px;
}
.courseDetailsItem1 {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 7px;
  background-color: var(--blackOp06);
}
.courseDetailsItem2 {
  width: 100%;
  height: 80px;
  overflow: hidden;
  border-radius: 7px;
  background-color: var(--blackOp06);
}
@media screen and (min-width: 768px) {
  .courseDetailsLoading {
    height: 280px;
  }
  .courseDetailsLoading2 {
    grid-template-columns: 2.5fr 1fr;
    height: auto;
    aspect-ratio: 1/0.25;
    gap: 20px;
  }
  .courseDetailsItem1,
  .courseDetailsItem2 {
    height: 100%;
  }
}
/* courseFirstSection */
.courseFirstSection {
  background-color: var(--bgClr);
  border-radius: 7px;
  margin-top: 20px;
  padding: 15px;
  box-shadow: 0 0 15px var(--blackOp12);
  gap: 20px;
}
.courseFirstLeftDiv {
  border-radius: 7px;
  overflow: hidden;
}
.courseLeftDivImg {
  width: 100%;
  height: auto;
  aspect-ratio: 2/1.1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.courseFirstLeftDiv::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: radial-gradient(
    transparent,
    var(--blackOp44) 60%,
    var(--blackOp85) 98%
  );
  z-index: 10;
  pointer-events: none;
  transition: opacity 0.4s;
  opacity: 0.6;
}
.courseFirstLeftDiv:hover::after {
  opacity: 0.75;
}
.courseFirstLeftDiv::before {
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--blackOp44);
  border-radius: 50%;
  z-index: 11;
  pointer-events: none;
  transition: width 0.4s, height 0.4s;
}
.courseFirstLeftDiv:hover::before {
  width: 80px;
  height: 80px;
}
.coursePlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--bgClr);
  height: 40px;
  width: 40px;
  z-index: 12;
  transition: width 0.4s, height 0.4s;
}
.courseFirstLeftDiv:hover .coursePlayIcon {
  width: 50px;
  height: 50px;
}
.courseTitle {
  font-weight: 600;
}
.shortDescription {
  margin-top: 5px;
  text-align: justify;
  font-weight: 500;
}
.choiceClassTypeDiv {
  margin-top: 20px;
  max-width: 550px;
  gap: 5px;
}
.courseTypeTitle {
  gap: 10px;
}
.classTypeDiv {
  align-items: center;
  gap: 15px;
}
.classTypeBtn {
  height: 40px;
  align-items: center;
  color: var(--mainClr1);
}
.classTypeBtn svg {
  margin-left: 5px;
}
.classTypeBtn .checkIcon {
  display: none;
}
.classTypeBtn.activeClassType .checkIcon {
  display: block;
}
.classTypeBtn.activeClassType .uncheckIcon {
  display: none;
}
.classPriceParentDiv {
  padding: 15px;
  border: 1px dashed var(--mainClr1);
  border-radius: 10px;
  margin-top: 20px;
  max-width: 550px;
}
.classPriceMainDiv {
  gap: 20px;
  align-items: center;
  margin-top: 15px;
}
.coursePrice {
  margin-right: auto;
}
@media screen and (min-width: 450px) {
  .classPriceMainDiv {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 649px) {
  .courseFirstSection {
    padding: 15px;
    gap: 20px;
  }
}
@media screen and (min-width: 968px) {
  .courseFirstSection {
    grid-template-columns: 1.5fr 350px;
    padding: 25px;
    gap: 30px;
  }
  .courseFirstLeftDiv {
    order: 2;
    height: fit-content;
  }
  .courseFirstLeftDiv {
    margin-top: 45px;
  }
  .choiceClassTypeDiv {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1100px) {
  .courseFirstSection {
    grid-template-columns: 1.5fr 1fr;
    gap: 40px;
  }
}
/* courseBodySection */
.courseBodySection {
  gap: 20px;
  margin-top: 20px;
}
.contentDescDiv,
.courseTitlesDiv,
.courseTeacherSection {
  background-color: var(--bgClr);
  border-radius: 7px;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 0 15px var(--blackOp12);
}
.contentDescDiv {
  margin-top: 0;
}
.courseTitlesList {
  margin-top: 10px;
}
.courseTitlesList div {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.courseTitlesList p {
  padding-right: 20px;
  font-size: 0.95em;
  font-weight: 500;
  color: var(--clr5);
  position: relative;
}
.courseTitlesList p::before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  right: 5px;
  top: 8px;
  border-radius: 50%;
  background-color: var(--blueClr2);
}
.courseTeacherGrid {
  margin-top: 20px;
  grid-template-columns: 100px 1fr;
  gap: 15px;
}
.courseTeacherImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.courseTeacherInfo {
  gap: 7px;
}
.courseBodyLeftDiv {
  gap: 10px;
}
.courseInfoItem {
  padding: 15px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 7px;
}
.courseInfoItem svg {
  color: var(--blueClr1);
  height: 20px;
  width: 20px;
}
.courseInfoItem .courseInfoDesc {
  margin-right: 15px;
}
.courseInfoTitle {
  gap: 10px;
}
@media screen and (min-width: 768px) {
  .courseBodySection {
    grid-template-columns: 2fr 1fr;
  }
  .courseInfoItem {
    flex-direction: column;
  }
  .courseBodyLeftDiv {
    height: fit-content;
    position: sticky;
    top: 100px;
  }
}
@media screen and (min-width: 968px) {
  .courseInfoItem {
    flex-direction: row;
    font-size: 0.95em;
  }
}
@media screen and (min-width: 1100px) {
  .courseBodySection {
    grid-template-columns: 1fr 380px;
  }
  .courseInfoItem {
    font-size: 1em;
  }
}
