.hintReserveDiv {
  background-color: var(--bgClr);
  border-radius: 7px;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 0 15px var(--blackOp12);
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  flex-wrap: wrap;
}
.hintItem {
  gap: 7px;
  padding: 3px 1px 3px 5px;
  white-space: nowrap;
}
.hintIcon {
  height: 18px;
  width: 25px;
  border-radius: 5px;
  overflow: hidden;
}
.hintIcon.choiced {
  background-color: rgba(30, 143, 255, 0.11);
  border: 1px solid dodgerblue;
}
.hintIcon.reserved {
  background-color: rgba(152, 166, 185, 0.24);
  border: 1px solid rgb(152, 166, 185);
}
.hintIcon.inProgress {
  background-color: #ffe8bd;
  border: 1px solid orange;
}
.hintIcon.reserved::after,
.hintIcon.inProgress::after {
  content: "";
  width: 30px;
  height: 8px;
  background-color: var(--bgClr);
  position: absolute;
  left: -5px;
  top: 2px;
  transform: rotate(-45deg);
}
.hintIcon.available {
  background-color: rgb(183, 243, 183);
  border: 1px solid rgb(35, 175, 35);
}
/* reserveMainSection */
.reserveMainSection {
  background-color: var(--bgClr);
  border-radius: 7px;
  box-shadow: 0 0 15px var(--blackOp12);
  margin-top: 20px;
  padding: 15px;
}
.reserveWeekGrid {
  grid-template-columns: 32px 180px 32px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid var(--blackOp12);
  border-radius: 7px;
}
.prevWeek,
.nextWeek {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 7px;
  background-color: transparent;
  transition: background-color 0.4s;
}
.prevWeek:hover,
.nextWeek:hover {
  background-color: rgba(30, 143, 255, 0.089);
}
.prevWeek.disable,
.nextWeek.disable {
  pointer-events: none;
  opacity: 0.4;
}
.weekDayNameMainDiv {
  margin-top: 30px;
  grid-template-columns: 1fr 7fr;
}
.reserveCalendarMainDiv {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.dayTimeText {
  font-size: 0.8em;
  height: 37px;
  border-bottom: 1px solid rgba(152, 166, 185, 0.39);
  color: var(--clr5);
  padding: 3px;
  max-width: 100%;
}
.calendarTimeList {
  border-right: 1px solid rgba(152, 166, 185, 0.39);
}
.calendarTimeList.first {
  border-right: 1px solid transparent;
}
.calendarTimeList:last-child {
  border-left: 1px solid rgba(152, 166, 185, 0.39);
}
.calendarTimeList.first .dayTimeText {
  border-bottom: 1px solid transparent;
  transform: translateY(-50%);
}
.dayNameText {
  height: 50px;
  font-size: 0.78em;
  text-align: center;
  padding: 3px;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(152, 166, 185, 0.144);
  border-top: 1px solid rgba(152, 166, 185, 0.39);
  border-bottom: 1px solid rgba(152, 166, 185, 0.39);
}
.calendarTimeList.first .dayNameText {
  background-color: transparent;
  border: 1px solid transparent;
}
.dayNameText.holiday {
  color: var(--redClr);
}
.reservedImg {
  display: none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}
.daySubTimeText {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.dayTimeText.reserved .daySubTimeText {
  background-color: rgba(152, 166, 185, 0.384);
  border: 1px solid rgba(152, 166, 185, 0.644);
}
.dayTimeText.reserved .reservedImg,
.dayTimeText.inProgress .reservedImg {
  display: block;
}
.itemHoverText {
  position: absolute;
  left: 50%;
  top: -80%;
  background-color: rgb(83, 91, 102);
  border-radius: 5px;
  padding: 5px 7px;
  white-space: nowrap;
  transform: translateX(-50%);
  display: none;
  gap: 6px;
  z-index: 20;
  pointer-events: none;
}
.itemHoverText::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid rgb(83, 91, 102);
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%);
}
.dayTimeText:hover .itemHoverText {
  display: -webkit-flex;
  display: flex;
}
.dayTimeText.inProgress .daySubTimeText {
  background-color: #ffe8bd;
  border: 1px solid orange;
}
.dayTimeText.available .daySubTimeText {
  background-color: rgb(183, 243, 183);
  border: 1px solid rgb(35, 175, 35);
  cursor: pointer;
}
.dayTimeText.choiced .daySubTimeText {
  background-color: rgba(30, 143, 255, 0.11);
  border: 1px solid dodgerblue;
  cursor: pointer;
}
.loadingCalendar,
.loadingNextCalendar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background-color: var(--bgClr);
  overflow: hidden;
}
.loadingNextCalendar {
  background-color: var(--blackOp12);
}
.loadingNextPageSpin {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border-left: 2px solid var(--clr5);
  border-right: 2px solid var(--clr5);
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  animation: spin 2s linear infinite;
}
.calendarTimeList.firstRow,
.calendarTimeList.firstRow .dayNameText {
  border-radius: 0 7px 0 0;
}
.calendarTimeList:last-child,
.calendarTimeList:last-child .dayNameText {
  border-radius: 7px 0 0 0;
}
/* resultReserveSection */
.resultReserveSection {
  background-color: var(--bgClr);
  border-radius: 7px;
  box-shadow: 0 0 15px var(--blackOp12);
  margin-top: 20px;
  padding: 15px;
}
.resultReserveTitle {
  color: var(--mainClr1);
  gap: 7px;
}
.resultReserveTitle svg,
.noReserveTime svg {
  transform: translateY(-2px);
}
.noReserveTime {
  margin-top: 15px;
  color: var(--redClr);
  background-color: rgba(255, 99, 71, 0.089);
  padding: 10px;
  border-radius: 7px;
  border: 1px solid var(--redClr);
  gap: 5px;
}
.confirmReserve {
  height: 45px;
  border-radius: 7px;
  background-color: var(--mainClr1);
  margin-top: 20px;
}
.confirmReserve.disable {
  pointer-events: none;
  opacity: 0.4;
}
.reserveList {
  margin-top: 15px;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}
.reserveItem {
  padding: 5px 10px 5px 5px;
  align-items: center;
  gap: 10px;
  background-color: rgba(30, 143, 255, 0.068);
  border: 1px solid rgba(30, 143, 255, 0.705);
  border-radius: 7px;
}
.removeReserveTime {
  color: var(--redClr);
  background-color: rgba(255, 99, 71, 0.151);
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
@media screen and (min-width: 768px) {
  .reserveMainGrid {
    grid-template-columns: 1fr 250px;
    gap: 20px;
  }
  .resultReserveSection {
    height: fit-content;
    position: sticky;
    top: 100px;
  }
  .dayTimeText {
    height: 40px;
  }
}
@media screen and (min-width: 968px) {
  .reserveMainGrid {
    grid-template-columns: 1fr 350px;
    gap: 20px;
  }
  .resultReserveSection {
    height: fit-content;
    position: sticky;
    top: 100px;
  }
  .dayTimeText {
    height: 42px;
  }
}
