.loginMainDiv {
  min-height: calc(100dvh - 220px);
  justify-content: center;
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}
.checkUserForm {
  padding: 10px;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.loginImg {
  width: 40%;
  max-width: 120px;
  margin: 0 auto;
}
.loginTitle {
  color: var(--clr3);
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 5px;
}
.loginText1 {
  font-size: 0.95em;
  color: var(--clr5);
  margin-top: 10px;
}
.loginFirstCheckInput {
  height: 45px;
  outline: none;
  border: none;
  border-radius: 5px;
  border: 1px solid var(--blackOp20);
  padding: 0 15px;
  font-size: 0.96em;
}
.loginFirstCheckInput:-webkit-autofill,
.loginFirstCheckInput:-webkit-autofill:hover,
.loginFirstCheckInput:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--bgClr) inset !important;
}
.loginFirstCheckInput.error {
  border: 1px solid var(--redClr);
}
.userCheckHintText {
  color: var(--redClr);
  opacity: 0;
  transition: opacity 0.4s;
}
.userCheckHintText.show {
  opacity: 1;
}
.submitBtn {
  height: 45px;
  background-color: var(--blueClr2);
  font-size: 1em;
  border-radius: 5px;
  margin-top: 20px;
}
.backBtn {
  margin-right: auto;
  color: var(--clr5);
  height: 37px;
  width: 37px;
  position: absolute;
  left: 0;
  top: 15px;
  background-color: var(--blackOp07);
  border-radius: 5px;
}
.backBtn svg {
  height: 16px;
  width: 16px;
}
.signCodeInputDiv {
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 20px 0;
  max-width: 320px;
  align-self: center;
  direction: ltr;
}
.signCodeInput {
  height: 40px;
  width: 40px;
  outline: none;
  border-radius: 5px;
  border: none;
  border: 1px solid var(--blackOp20);
  text-align: center;
}
.signCodeInput::-webkit-outer-spin-button,
.signCodeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.signCodeInput[type="number"] {
  -moz-appearance: textfield;
}
.signLoadingSpin {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-right: 4px solid var(--blackOp20);
  border-top: 4px solid var(--blackOp20);
  border-left: 4px solid var(--blackOp06);
  border-bottom: 4px solid var(--blackOp06);
  animation: spin 2s linear infinite;
}
.loadingProgressMainDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 800;
}
.customFormInput {
  height: 50px;
  width: 100%;
  padding: 0 15px 0 68px;
  background-color: transparent;
  border: 1px solid var(--blackOp20);
  border-radius: 7px;
  transition: border 0.4s;
  outline: none;
  margin-top: 3px;
  direction: ltr;
}
.customSelect {
  height: 45px;
  width: 100%;
  padding: 0 15px 0 40px;
  background-color: var(--blackOp06);
  border: 1px solid var(--blackOp20);
  border-radius: 5px;
  transition: border 0.4s;
  outline: none;
  margin-top: 3px;
  background-image: url("/public/icon/arrowDown.svg");
  background-repeat: no-repeat;
  background-position: left 14px center;
  background-size: 20px;
  font-size: 0.85em;
  font-weight: 500;
  color: var(--clr3);
  -webkit-appearance: none;
  -moz-appearance: none;
}
.customSelect::-ms-expand {
  display: none;
}
.customFormInput:focus,
.customSelect:focus {
  border: 1px solid var(--blackOp44);
}
.customFormInput.login:placeholder-shown {
  direction: rtl;
}
.toggleLoginPassBtn {
  height: 30px;
  width: 50px;
  position: absolute;
  left: 0;
  bottom: 10px;
  border-right: 1px solid var(--blackOp20);
}
.formBtnDiv {
  margin-top: 30px;
  gap: 5px;
}
.formFooterActionDiv {
  margin-top: 10px;
}
.formBackToNumber,
.requestCodeAgainBtn {
  color: var(--blueClr2);
  height: 30px;
  padding: 0 10px 0 7px;
  border-radius: 3px;
  transition: background-color 0.4s;
}
.formBackToNumber:hover {
  background-color: var(--blueClr4);
}
.requestCodeAgainBtn {
  color: var(--redClr);
}
.timerText {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr5);
}
.requestCodeAgainBtn:hover {
  background-color: rgba(255, 99, 71, 0.082);
}
