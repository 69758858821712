/* paginationDiv */
.paginationDiv {
  margin-top: 30px;
  direction: ltr;
  gap: 10px;
}
.pageItem,
.prePage,
.nextPage,
.currentPage {
  height: 35px;
  width: 35px;
  background-color: var(--blackOp07);
  transition: background-color 0.4s;
  border-radius: 5px;
}
.pageItem:hover,
.prePage:hover,
.nextPage:hover {
  background-color: var(--blackOp20);
}
.prePage svg,
.nextPage svg {
  color: var(--clr3);
}
.currentPage {
  background-color: var(--mainClr1);
  pointer-events: none;
  color: var(--bgClr);
}
.loadingPagintaionDiv {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  z-index: 100;
  background-color: var(--blackOp40);
}
.loadingPagintaionSpin {
  height: 100%;
  width: 40%;
  animation: loadingPagination 0.8s linear infinite alternate;
  position: absolute;
  top: 0;
  background-color: var(--mainClr1);
}
@keyframes loadingPagination {
  from {
    left: 0;
  }
  to {
    left: 60%;
  }
}
