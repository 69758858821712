.courseItemMainDiv {
  margin: 0 5px;
  border: 1px solid #00000013;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px var(--blackOp12);
}
.courseImg {
  width: 100%;
  aspect-ratio: 2/1.1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.courseInfoDiv {
  padding: 20px 15px 0;
  border-radius: 10px;
  transform: translateY(-10px);
  background-color: var(--bgClr);
}
.courseName {
  font-size: 1.07em;
  width: fit-content;
}
.courseTeacher {
  margin-top: 12px;
  gap: 7px;
  color: var(--clr7);
  font-size: 0.8em;
}
.courseTeacher svg {
  color: var(--blueClr2);
}
.courseDivider {
  background-color: var(--blackOp20);
  width: 100%;
  height: 1px;
  margin-top: 15px;
}
.courseItemFooter {
  margin-top: 12px;
}
.courseItemPrice {
  font-size: 1em;
  color: var(--greenClr1);
}
.courseDiscount {
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 5px;
  background-color: var(--redClr);
  z-index: 40;
  padding: 3px 7px;
  color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp20);
  gap: 3px;
}
.courseDiscount svg {
  font-size: 0.9em;
  transform: translateY(-1px);
}
