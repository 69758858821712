.imgSliderContainer {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  max-height: 75dvh;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
  border-radius: 10px;
  padding: 20px;
}
.imgSliderContainer.show {
  display: -webkit-flex;
  display: flex;
}
.imgSlider {
  width: 100%;
}
.imgSlider .swiper-wrapper {
  align-items: center !important;
}
.fullImgSliderImg {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  max-height: 500px;
}
.imgSlider .goToNextSlide,
.imgSlider .goToPreSlide {
  width: 40px;
  height: 40px;
  background-color: #ffffff33;
  border-radius: 50%;
  color: var(--clr3);
  position: absolute;
  right: 10px;
  z-index: 1002;
  top: 50%;
  transform: translateY(-50%);
}
.imgSlider .goToPreSlide {
  right: calc(100% - 50px);
}
.imageFullSlderText {
  position: absolute;
  right: 50%;
  bottom: 15px;
  padding: 3px 15px;
  background-color: var(--blackOp44);
  border-radius: 5px;
  color: var(--bgClr);
  transform: translateX(50%);
}
@media screen and (min-width: 768px) {
  .imgSlider .goToNextSlide {
    right: 0;
  }
  .imgSlider .goToPreSlide {
    right: calc(100% - 40px);
  }
}
