input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
/* header */
.headerTopMinImage {
  width: 100%;
}
.headerTopLargeImage {
  width: 100%;
  display: none;
}
header {
  z-index: 980;
  position: sticky;
  top: 0;
  background-color: var(--bgClr);
  box-shadow: 0 8px 15px #00000013;
}
.flexMainDiv {
  width: 96%;
}
.headerRightDiv {
  height: 100%;
  gap: 15px;
}
.headerCallLeftDiv {
  margin-right: 30px;
}
.userBtn,
.cartBtn,
.loginBtn {
  height: 35px;
  width: 35px;
}
.menuBtn {
  padding: 0 20px 0 10px;
  height: 60px;
  border-right: 1px solid var(--blackOp20);
}
.menuBtn svg,
.userBtn svg,
.cartBtn svg {
  height: 20px;
  width: 20px;
  color: var(--clr5);
}
.headerMainLogo {
  gap: 3px;
  font-size: 1.2em;
}
.headerMainLogo span {
  color: var(--blueClr1);
  line-height: 0.9em;
}
.headerMainLogoImg {
  width: auto;
  height: 40px;
}
.headerCallDiv {
  display: none;
}
.headerCallContainer {
  height: 100%;
}
.headerCallItem {
  gap: 7px;
}
.headerCallItem span {
  direction: ltr;
  padding-top: 3px;
}
.headerDeskDiv {
  display: none;
}
.loginBtn span {
  display: none;
}
.loginBtn svg {
  height: 25px;
  width: 25px;
  color: var(--clr5);
}
.cartHoverDiv {
  display: none;
}
.cartNumberText {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--redClr);
  color: var(--bgClr);
  padding-top: 5px;
  right: 0;
  top: 0;
  transform: translate(30%, -30%);
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .headerTopLargeImage {
    display: block;
  }
  .headerTopMinImage {
    display: none;
  }
  header {
    border-bottom: 1px solid var(--blackOp20);
    z-index: 990;
  }
  .headerCallDiv {
    display: -webkit-flex;
    display: flex;
    height: 60px;
    border-bottom: 1px solid var(--blackOp20);
    z-index: 890;
  }
  .flexMainDiv {
    height: 80px;
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
  }
  .headerDeskDiv {
    display: -webkit-flex;
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .headerDeskLink {
    gap: 5px;
  }
  .headerDropSubMainDiv {
    position: absolute;
    right: 0;
    top: 50px;
    pointer-events: none;
    opacity: 0;
    width: 100%;
  }
  .headerDropSubDiv {
    background-color: var(--bgClr);
    margin-top: 30px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s, transform 0.3s;
    pointer-events: none;
    box-shadow: 0 15px 15px var(--blackOp20);
    height: 450px;
    border-radius: 0 0 7px 7px;
    overflow-y: hidden;
  }
  .headerItemParentDiv {
    width: 150px;
  }
  .headerRightDiv {
    justify-content: flex-end;
    gap: 5px;
  }
  .headerDropDiv:hover .headerDropSubDiv {
    pointer-events: unset;
    opacity: 1;
    transform: translateY(0);
  }
  .headerDropShowMenuBtn {
    height: 40px;
    padding: 0 10px 0 5px;
    justify-content: space-between;
    transition: background-color 0.4s;
    width: 150px;
  }
  .headerItemParentDiv:first-child .headerDropShowMenuBtn {
    background-color: var(--blackOp06);
  }
  .headerDropSubLink {
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.4s;
    min-width: 150px;
  }
  .headerDropSubLink:hover {
    background-color: var(--blackOp09);
  }
  .headerDropDiv:hover .headerDropSubMainDiv {
    pointer-events: unset;
    opacity: 1;
  }
  .headerMegaMenuLeftDiv {
    position: absolute;
    left: 0;
    top: 0;
    height: 450px;
    width: calc(100% - 150px);
    flex-direction: column;
    padding: 0 15px;
    border-right: 1px solid var(--blackOp20);
    background-color: #f9f9f9;
    opacity: 0;
    pointer-events: none;
  }
  .headerItemParentDiv.active .headerMegaMenuLeftDiv,
  .headerDropShowMenuBtn:hover + .headerMegaMenuLeftDiv,
  .headerItemParentDiv:hover .headerMegaMenuLeftDiv {
    opacity: 1;
    pointer-events: unset;
  }
  .headerDropSubDiv:hover
    .headerItemParentDiv:not(:hover)
    .headerDropShowMenuBtn {
    background-color: transparent;
  }
  .headerItemParentDiv:hover .headerDropShowMenuBtn,
  .headerItemParentDiv.active .headerDropShowMenuBtn {
    background-color: var(--blackOp06);
  }
  .headerMegaMenuLeftDiv.row3 {
    grid-template-columns: 1fr 2fr 1fr;
    gap: 20px;
  }
  .headerMegaMenuLeftDiv.row1 {
    grid-template-columns: 1fr 0.2fr;
  }
  .headerDropSubTitleLink {
    height: 39px;
    align-items: center;
    padding: 0 10px 0 40px;
    border-bottom: 1px solid var(--blackOp20);
    transition: background-color 0.4s;
  }
  .headerMegaMenuRow {
    max-height: 100%;
  }
  .headerMegaMenuSubRow {
    flex-wrap: wrap;
    max-height: 420px;
    margin-top: 5px;
  }
  .headerCallDiv .mobileSearchDiv {
    width: 350px;
    margin-right: auto;
  }
  /* cart */
  .cartHoverDiv {
    display: -webkit-flex;
    display: flex;
    transform: translateY(20px);
    opacity: 0;
    transition: opacity 0.4s, transform 0.4s;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 800;
    width: 330px;
  }
  .cartMainHoverDiv:hover .cartHoverDiv {
    opacity: 1;
    transform: translateY(0);
    pointer-events: unset;
  }
  .cartHoverSubDiv {
    margin-top: 20px;
    padding: 15px 0;
    background-color: var(--bgClr);
    border-radius: 10px;
    box-shadow: 0 0 15px var(--blackOp20);
  }
  .emptyCartDiv {
    gap: 10px;
  }
  .emptyCartDiv svg {
    height: 60px;
    width: 60px;
    margin: 0 auto;
    color: var(--clr5);
    opacity: 0.6;
  }
  .headerMainLogo {
    width: fit-content;
  }
  .headerDeskLink {
    position: relative;
  }
  .headerDeskLink::after,
  .headerDeskLink::before {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -7px;
    background-color: var(--clr7);
    transition: width 0.4s;
  }
  .headerDeskLink::before {
    left: unset;
    right: 0;
  }
  .headerDeskLink:hover::after,
  .headerDeskLink:hover::before,
  .headerDropDiv:hover .headerDeskLink::after,
  .headerDropDiv:hover .headerDeskLink::before {
    width: 50%;
  }
  .schoolsList {
    padding: 15px;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 100%;
    align-items: flex-start;
    justify-content: right;
  }
  .schoolsList .headerDropSubLink {
    width: fit-content;
  }
}
@media screen and (min-width: 1100px) {
  .headerDeskDiv {
    gap: 30px;
  }
  .headerDeskLink {
    font-size: 0.98em;
  }
  .loginBtn {
    width: fit-content;
    padding: 10px 10px 10px 15px;
    border: 1px solid var(--blackOp20);
    border-radius: 7px;
    position: relative;
    gap: 10px;
    transition: background-color 0.4s;
  }
  .loginBtn:hover {
    background-color: var(--blackOp06);
  }
  .loginBtn svg {
    height: 16px;
    width: 16px;
  }
  .loginBtn span {
    display: block;
    font-size: 1em;
  }
  .flexMainDiv {
    grid-template-columns: 180px 1fr 180px;
  }
}
@media screen and (min-width: 1000px) {
  .headerItemParentDiv {
    width: 250px;
  }
  .headerDropShowMenuBtn {
    width: 250px;
  }
  .headerMegaMenuLeftDiv {
    width: calc(100% - 250px);
  }
  .headerMegaMenuLeftDiv.row3 {
    grid-template-columns: 1fr 2fr 1fr 0.6fr;
    gap: 20px;
  }
  .headerMegaMenuLeftDiv.row1 {
    grid-template-columns: 1fr 1.5fr;
  }
}
/* navCloserDiv */
.navCloserDiv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background-color: #000;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}
.navCloserDiv.active {
  opacity: 0.8;
  pointer-events: unset;
}
.navCloseBtn {
  position: absolute;
  left: 40px;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--bgClr);
  background-color: #ffffff62;
  transform: translateY(-50%);
}
.navCloseBtn svg {
  height: 20px;
  width: 20px;
}
.nav {
  position: fixed;
  right: 0;
  top: 0;
  height: 100dvh;
  width: 70%;
  background-color: var(--bgClr);
  z-index: 1001;
  pointer-events: none;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0s 0.3s;
  grid-template-rows: auto 1fr 60px;
  max-width: 380px;
}
.nav.active {
  transform: none;
  opacity: 1;
  transition: transform 0.4s;
  pointer-events: unset;
}
.navLinkList {
  padding: 0 10px;
  gap: 5px;
  margin-top: 15px;
  max-height: 100%;
  overflow-y: auto;
}
.navLogo {
  height: 40px;
  margin: 10px auto 5px;
}
.expandParentDiv {
  gap: 5px;
}
.showSubListBtn {
  width: 40px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 0;
  border-right: 1px solid var(--blackOp20);
}
.subToggleBtn {
  width: 40px;
  height: 25px;
  border-right: 1px solid var(--blackOp20);
  position: absolute;
  left: 0;
  top: 10px;
}
.showSubListBtn svg,
.subToggleBtn svg {
  height: 20px;
  width: 20px;
  color: var(--clr7);
  transition: transform 0.4s;
}
.showSubListBtn.toggle svg,
.subToggleBtn.toggle svg {
  transform: rotate(-180deg);
}
.expandList {
  overflow: hidden;
  max-height: 0;
  background-color: var(--blackOp06);
}
.expandSubList {
  gap: 7px;
  border-right: 3px solid var(--blackOp20);
}
.firstSubList {
  padding: 5px 0;
}
.secendSubList {
  height: 0;
  gap: 7px;
  overflow: hidden;
}
.secendSubList > div {
  margin-right: 15px;
  margin-top: 10px;
  border-right: 3px solid var(--blackOp20);
  padding-right: 5px;
  gap: 7px;
  background-color: var(--blackOp06);
}
.thirdSubList {
  padding: 5px 10px 5px 0;
  gap: 5px;
  margin-right: 10px;
  background-color: var(--blackOp06);
}
.subToggleBtn.toggle + .secendSubList {
  height: unset;
}
.showSubListBtn.toggle + .expandList {
  max-height: unset;
}
.navLogoDiv {
  margin: 0 15px;
}
.navSearchIcon {
  position: absolute;
  left: 0;
  top: 0;
  height: 45px;
  width: 45px;
}
.mobileSearchInput {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--blackOp20);
  height: 45px;
  padding: 0 5px 0 45px;
}
.navlinkItem {
  padding: 5px 5px 5px 10px;
  width: fit-content;
}
.subListTitle {
  width: 100%;
  padding: 10px 5px;
}
.navFooterDiv {
  height: 100%;
  gap: 7px;
}
.navSocialItem {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  box-shadow: 0 10px 25px #00000013;
  color: var(--clr7);
}
.firstSubList > .navlinkItem {
  padding: 7px 10px 7px 15px;
}
@media screen and (min-width: 768px) {
  .navCloserDiv,
  .nav,
  .menuBtn {
    display: none;
  }
}
/* headerCartDetails */
.cartHoverLoadingDiv {
  height: 150px;
}
.cartHeaderSpin {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 40px;
  width: 40px;
  margin: -20px 0 0 -20px;
  border-radius: 50%;
  border-top: 4px solid var(--blackOp12);
  border-right: 4px solid var(--blackOp12);
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation: spin 1s linear infinite;
}
.headerCartList {
  gap: 30px;
  max-width: 350px;
  overflow-y: auto;
  padding: 20px 15px;
}
.headerCartItem {
  position: relative;
}
.headerCartItem::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -15px;
  background-color: var(--blackOp09);
}
.headerCartItem:last-child::after {
  display: none;
}
.cartItemTopDiv {
  grid-template-columns: 70px 1fr;
  gap: 15px;
  align-items: flex-start;
}
.cartItemImage {
  height: auto;
  width: 70px;
  border-radius: 4px;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border: 1px solid var(--blackOp09);
}
.headerCartItemName {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr3);
}
.headerCartItemRemove {
  height: 30px;
  width: 30px;
  color: var(--redClr);
  border-radius: 7px;
  background-color: var(--blackOp06);
  transition: background-color 0.3s;
}
.headerCartItemRemove:hover {
  background-color: var(--blackOp12);
}
.headerCartItemManageDiv {
  margin-top: 15px;
  border-top: 1px solid var(--blackOp12);
  padding-top: 7px;
}
.headerCartItemManageDiv .productCartManageDiv {
  height: 32px;
  grid-template-columns: 30px 40px 30px;
}
.headerCartItemManageDiv .decreaseNumberBtn,
.headerCartItemManageDiv .increaseNumberBtn {
  height: 30px;
}
.headerCartItemManageDiv .decreaseNumberBtn svg,
.headerCartItemManageDiv .increaseNumberBtn svg {
  width: 17px;
  height: 17px;
}
.headerCartItemPriceDiv {
  margin-top: 5px;
}
.headerCartItemPrice {
  font-size: 15px;
  font-weight: 500;
  color: var(--clr3);
  margin-right: auto;
}
.headerCartItemPrice span {
  font-size: 12px;
  color: var(--clr7);
  margin-right: 2px;
}
.addCartLoading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffbe;
  gap: 3px;
  border-radius: 4px;
}
.addCartLoadingDot {
  background-color: var(--clr7);
  height: 6px;
  width: 6px;
  border-radius: 50%;
  animation: pulse 1.2s linear infinite alternate;
}
.addCartLoadingDot:nth-child(2) {
  animation-delay: 0.4s;
}
.addCartLoadingDot:nth-child(3) {
  animation-delay: 0.8s;
}
.headerCartItem .addCartLoadingDot {
  height: 4px;
  width: 4px;
}
.headerHasDataList {
  grid-template-rows: 35px 1fr 35px;
}
.headerHasHeaderDiv {
  height: 100%;
  border-bottom: 1px solid var(--blackOp12);
  padding: 0 15px 5px 15px;
}
.headerHasItemName {
  font-size: 1em;
  font-weight: 500;
  color: var(--clr3);
}
.headerHasItemLink,
.headerTotalPrice {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--blueClr1);
  border-radius: 4px;
  padding: 3px 7px;
  transition: background-color 0.4s;
}
.headerHasItemLink:hover {
  background-color: var(--blueClrOp1);
}
.headerCartFooter {
  height: 100%;
  border-top: 1px solid var(--blackOp12);
  padding: 10px 15px 0;
}
.headerCartSummaryText {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr3);
}
.headerTotalPrice {
  font-size: 1.05em;
  color: var(--clr3);
  font-weight: 600;
}
.headerCartItemPriceLine {
  font-size: 0.85em;
  font-weight: 500;
  color: var(--clrA);
  text-decoration: line-through;
  text-decoration-color: var(--clr7);
}
.headerCartItemPercent {
  color: var(--bgClr);
  font-weight: 400;
  font-size: 0.8em;
  border-radius: 4px;
  padding: 1px 4px 1px 7px;
  background-color: var(--redClr);
  gap: 3px;
}
.headerCartItemPercent svg {
  height: 12px;
  width: 12px;
}
.headerTotalPrice span {
  margin-right: 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--clr7);
}
@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}
