.profileVidoesItemName {
  font-size: 1em;
  font-weight: 500;
  color: var(--clr5);
  margin-top: 15px;
  text-align: justify;
}
.profileVidoesActionDiv {
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--blackOp12);
}
.profileVideosItem,
.profileVideoHeaderDiv {
  box-shadow: 0 0 15px var(--blackOp12);
  background-color: var(--bgClr);
  border-radius: 7px;
  padding: 15px;
}
.profileHeaderText {
  font-size: 1.1em;
  color: var(--clr3);
  font-weight: 500;
  gap: 7px;
}
.uploadVideoBtn {
  background-color: var(--blueClr2);
  border-radius: 4px;
  font-size: 0.87em;
  color: var(--bgClr);
  padding: 3px 7px 3px 10px;
}
.profileVideosRemoveBtn,
.profileVideosSeeBtn {
  height: 35px;
  width: 35px;
  border-radius: 4px;
  background-color: var(--redClr);
  color: var(--bgClr);
}
.profileVideosSeeBtn {
  background-color: var(--blueClr1);
}
.profileVideosTitle {
  font-size: 1.12em;
  font-weight: 500;
  color: var(--clr3);
  gap: 10px;
}
.profileVideosGrid {
  margin-top: 15px;
  gap: 15px;
}
.profileVideosHolderVideoDiv {
  height: auto;
  aspect-ratio: 1.7/1;
  border-radius: 7px;
  overflow: hidden;
  background-color: var(--blackOp12);
  max-height: 250px;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.profileVidoesImg {
  width: auto;
  position: absolute;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
.playLogoP {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  background-color: var(--whiteClrOp60);
  z-index: 20;
  border-radius: 50%;
  pointer-events: none;
}
.playLogoP svg {
  color: var(--bgClr);
  height: 30px;
  width: 30px;
}
.playLogoP::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  animation: pulsePlayLogo 1.5s linear infinite;
  transform: translate(-50%, -50%);
  background-color: var(--whiteClrOp60);
  border-radius: 50%;
  z-index: 8;
}
.profileVideoItemState {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--clr5);
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 25px;
  padding: 4px 7px 4px 10px;
}
.profileVideoItemState.pending {
  background-color: var(--blackOp12);
}
.profileVideoItemState.published {
  background-color: var(--greenClr1);
  color: var(--bgClr);
}
@keyframes pulsePlayLogo {
  from {
    width: 0;
    height: 0;
    opacity: 0.8;
  }
  to {
    width: 140%;
    height: 140%;
    opacity: 0;
  }
}
@media screen and (min-width: 649px) {
  .profileVideosGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1280px) {
  .profileVideosGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
