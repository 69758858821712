.blogsLoadingSection {
  margin-top: 20px;
}
.loadingLatestBlogs {
  border-radius: 7px;
  background-color: var(--blackOp06);
  width: 100%;
  height: 40px;
  overflow: hidden;
}
.loadingBlogsMiddleGrid {
  margin-top: 20px;
  gap: 20px;
}
.loadingBlogSearchDiv {
  border-radius: 7px;
  background-color: var(--blackOp06);
  width: 100%;
  height: 50px;
  overflow: hidden;
}
.loadingBlogResultList {
  gap: 20px;
}
.loadingBlogItem {
  border-radius: 7px;
  background-color: var(--blackOp06);
  width: 100%;
  height: 200px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .blogsLoadingSection {
    margin-top: 30px;
  }
  .loadingBlogsMiddleGrid {
    grid-template-columns: 250px 1fr;
  }
  .loadingBlogSearchDiv {
    position: sticky;
    top: 100px;
    height: 200px;
  }
}
@media screen and (min-width: 900px) {
  .loadingBlogResultList {
    grid-template-columns: 1fr 1fr;
  }
}
.filterListDiv {
  position: absolute;
  padding: 15px;
  background-color: var(--bgClr);
  box-shadow: 0 10px 5px var(--blackOp12);
  border-radius: 7px;
  z-index: 300;
  width: 100%;
  top: 55px;
  display: none;
  max-height: 250px;
  overflow-y: auto;
  left: 0;
  gap: 20px;
}
.filterListDiv.active {
  display: -webkit-flex;
  display: flex;
}
.filterListDiv .filterRow {
  border-bottom: 1px solid var(--blackOp20);
  padding-bottom: 20px;
}
.filterListDiv .filterRow:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.blogResultList {
  gap: 20px;
}
.blogsListGrid {
  padding-top: 10px;
  gap: 20px;
}
.suggestCatTitle {
  pointer-events: none;
}
.tagsList {
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.suggestList {
  margin-top: 10px;
  gap: 10px;
}
.suggestTagItem {
  padding: 4px 7px 4px 10px;
  border-radius: 5px;
  background-color: var(--blackOp06);
  border: 1px solid var(--blackOp20);
  gap: 5px;
  cursor: pointer;
}
.suggestCatItem {
  padding: 10px;
  transition: background-color 0.3s;
  border-radius: 5px;
  position: relative;
}
.suggestCatItem::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--blackOp12);
  position: absolute;
  left: 0;
  bottom: -5px;
}
.suggestCatItem:hover {
  background-color: var(--blackOp06);
}
.suggestCatItem:last-child::after {
  display: none;
}
.activeSuggestTagItem {
  border: 1px solid var(--blueClrOp2);
  background-color: var(--blueClrOp1);
}
.priceBeforeOff {
  color: var(--clr9);
  font-size: 0.8em;
  font-weight: 400;
  text-decoration: line-through;
  text-decoration-color: var(--clr7);
}
@media screen and (min-width: 649px) {
  .blogResultList {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .blogSlider .goToNextSlide,
  .blogSlider .goToPreSlide {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 75px;
    height: 35px;
    width: 35px;
    z-index: 80;
    background-color: var(--blackOp06);
    border-radius: 50%;
  }
  .blogSlider .goToNextSlide {
    left: 40px;
  }
  .blogSliderInfo {
    padding: 15px 15px 0 0;
  }
  .blogSliderImg {
    border-radius: 7px;
  }
  .blogsPageTitle {
    width: 100%;
  }
  .suggestCatItem {
    font-size: 0.85em;
  }
}
@media screen and (min-width: 968px) {
  .blogsListGrid {
    grid-template-columns: 290px 1fr;
    gap: 20px;
    margin-top: 0;
  }
  .blogResultList {
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
  }
  .blogsListGrid .rightSideSearchDiv {
    max-width: 550px;
  }
  .filterListDiv {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    gap: 20px;
    padding: 0;
    overflow: unset;
    position: unset;
  }
  .filterListDiv .filterRow {
    background-color: var(--bgClr);
    box-shadow: 0 5px 10px var(--blackOp12);
    border-radius: 7px;
    padding: 10px !important;
    border-bottom: none;
  }
}
@media screen and (min-width: 1100px) {
  .blogResultList {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .blogSliderItem {
    grid-template-columns: 1fr;
    transform: none;
    gap: 10px;
  }
  .blogSliderItem {
    transform: scale(0.9);
    gap: 10px;
  }
}
.mostVisited .blogTitle1,
.mostVisited .seeAllBlogs {
  display: none;
}
.mostVisited .homeBlogSection {
  width: 100%;
}
/* activeTagList */
.activeTagList {
  margin-top: 10px;
  gap: 10px;
}
.activeTagList .suggestTagItem {
  border: none;
  box-shadow: 0 0 15px var(--blackOp12);
  background-color: var(--bgClr);
  padding: 8px 15px 8px 10px;
  color: var(--clr5);
}
@media screen and (min-width: 768px) {
  .activeTagList {
    margin: 0 auto;
  }
}
