.mobileSliderImg {
  width: 100%;
}
.tabletSliderImg,
.desktopnSliderImg {
  display: none;
  width: 100%;
}
.sliderSwiper .goToNextSlide,
.sliderSwiper .goToPreSlide {
  height: 45px;
  width: 45px;
  background-color: var(--blackOp20);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 40;
}
.sliderSwiper .goToNextSlide svg,
.sliderSwiper .goToPreSlide svg {
  color: var(--bgClr);
  width: 17px;
  height: 17px;
}
.sliderSwiper .goToNextSlide {
  right: 20px;
}
.sliderSwiper .goToPreSlide {
  left: 20px;
}
@media screen and (min-width: 768px) {
  .mobileSliderImg {
    display: none;
  }
  .tabletSliderImg {
    display: block;
  }
}
@media screen and (min-width: 1150px) {
  .tabletSliderImg {
    display: none;
  }
  .desktopnSliderImg {
    display: block;
  }
}
