.productListItem {
  grid-template-columns: 80px 1fr;
  padding: 15px;
  border-radius: 7px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  gap: 10px;
  align-items: center;
}
.productImgLinkItem {
  width: 100%;
  height: auto;
  aspect-ratio: 1/01;
  border-radius: 5px;
  overflow: hidden;
}
.productImg {
  width: 100%;
  aspect-ratio: 1/01;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.productGridList {
  gap: 10px;
}
.productDiscount {
  background-color: var(--redClr);
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--bgClr);
  padding: 1px 4px 1px 8px;
}
.productsFooterItem {
  margin-top: 15px;
}
.productNotExistText {
  color: var(--redClr);
  font-size: 0.9em;
  font-weight: 500;
}
@media screen and (min-width: 550px) {
  .productInfoItem {
    padding: 10px 0 0;
  }
  .productGridList {
    grid-template-columns: 1fr 1fr;
  }
  .productListItem {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 768px) {
  .productGridList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 968px) {
  .productGridList {
    grid-template-columns: 1fr 1fr;
  }
  .productsRightDiv .filterListDiv {
    background-color: var(--bgClr);
    box-shadow: 0 0 15px var(--blackOp12);
    border-radius: 4px;
    padding: 5px;
    max-height: 350px;
    overflow-y: auto;
  }
  .productNavItem {
    border-right: 3px solid transparent;
  }
  .productActiveNavItem {
    border-right-color: var(--redClr);
  }
}
@media screen and (min-width: 1168px) {
  .productGridList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
