/* loading */
.productLoadingGrid {
  gap: 15px;
  margin-top: 10px;
}
.productLeftLoading {
  gap: 15px;
}
.productLoadingItem {
  width: 100%;
  aspect-ratio: 1/0.9;
  background-color: var(--blackOp07);
  border-radius: 7px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .productLoadingGrid {
    grid-template-columns: 1fr 1fr;
  }
  .productLeftLoading .productLoadingItem {
    aspect-ratio: 1/0.5;
  }
  .productLoadingGrid > .productLoadingItem {
    height: 100%;
  }
}
@media screen and (min-width: 968px) {
  .productLoadingGrid {
    grid-template-columns: 350px 1fr;
    margin-top: 25px;
  }
  .productLeftLoading {
    grid-template-columns: 1fr 280px;
  }
  .productLeftLoading .productLoadingItem {
    aspect-ratio: 1/1;
  }
}
/* content */
.productMainDiv {
  margin-top: 20px;
  gap: 20px;
}
.productMainSubGrid {
  gap: 20px;
}
.image-slider {
  width: 100%;
}
.image-slider .swiper-slide {
  padding-bottom: 27px;
}
.productImgSlider {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.swiper-pagination {
  position: absolute !important;
  max-width: 80% !important;
  padding: 7px;
  background-color: var(--bgClr);
  left: 50% !important;
  bottom: 2px;
  height: 50px;
  transform: translateX(-50%) !important;
  right: unset;
  border-radius: 4px;
  box-shadow: 0 0 15px var(--blueClrOp2);
  display: -webkit-flex;
  display: flex;
  gap: 7px;
  z-index: 50 !important;
}
.productImgBullet {
  height: 100% !important;
  width: auto !important;
  aspect-ratio: 1/1;
  border-radius: 3px !important;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  transform: none !important;
  transition: right 0.4s, left 0.4s, opacity 0.4s !important;
  padding: 0 !important;
  margin: 0 !important;
  user-select: none;
  z-index: 100 !important;
}
.productMiddleDiv {
  position: relative;
}
.productName {
  font-size: 1.4em;
  font-weight: 500;
  color: var(--clr3);
}
.productPropTitle {
  font-size: 1.08em;
  margin-top: 15px;
  gap: 7px;
  color: var(--clr3);
  border-top: 1px solid var(--blackOp12);
  padding-top: 15px;
}
.topPropList {
  margin-top: 7px;
  gap: 3px;
}
.topPropsItem {
  grid-template-columns: auto auto 1fr;
  position: relative;
  margin-right: 25px;
  padding-right: 20px;
  gap: 5px;
  font-size: 0.95em;
  font-weight: 500;
  color: var(--clr5);
}
.topPropsItem::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 8px;
  background-color: var(--clr7);
  opacity: 0.7;
}
.productCartDiv {
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--bgClr);
  padding: 10px 15px;
  z-index: 860;
  box-shadow: -5px 0 15px var(--blackOp20);
  border-top: 1px solid var(--blackOp07);
  align-items: center;
}
.productCartManageDiv {
  grid-template-columns: 40px 1fr 40px;
  background-color: var(--blackOp07);
  border-radius: 5px;
  border: 1px solid var(--blackOp12);
  align-items: center;
  padding: 0 5px;
}
.increaseNumberBtn,
.decreaseNumberBtn {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  color: var(--redClr);
}
.showProductNumber {
  font-size: 1.2 em;
  font-weight: 500;
  color: var(--clr3);
}
.productTitleDiv {
  justify-content: flex-end;
  width: fit-content;
}
.productPriceTitleText {
  font-size: 0.9em;
  color: var(--clr5);
  display: none;
}
.productCartLeftDiv {
  margin-right: auto;
}
.productPrice {
  font-size: 0.98em;
  color: var(--clr3);
  font-weight: 500;
  margin-top: -1px;
}
.productPrice span {
  font-size: 0.85em;
  margin-right: 7px;
  color: var(--clr7);
}
.cartAddBtn {
  height: 38px;
  border-radius: 5px;
  background-color: dodgerblue;
  color: var(--bgClr);
  font-size: 0.95em;
  font-weight: 500;
}
.percentDiv {
  position: absolute;
  left: 0;
  top: -1px;
}
.productPercent {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding: 1px 6px 0 5px;
  background-color: var(--redClr);
  font-size: 0.9em;
  font-weight: 500;
  color: var(--bgClr);
  border-radius: 5px;
  gap: 2px;
}
.percentDiv svg {
  width: 12.5px;
  height: 12.5px;
  transform: translateY(-2px);
}
.discountDiv .priceBeforeOff {
  font-size: 0.9em;
  margin-top: 2px;
}
.productSupportDiv {
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5px;
  padding-top: 20px;
  border-top: 1px solid var(--blackOp12);
}
.profileSupportIcon {
  height: 45px;
  width: 38px;
}
.profileSupportText {
  font-size: 0.85em;
  font-weight: 500;
  color: var(--clr5);
  margin-top: 10px;
}
.profileSupportIcon2 {
  height: 45px;
  width: 45px;
}
.priceBeforeOff .tomanText {
  display: none;
}
.productNotExistBtn {
  color: var(--bgClr);
  height: 38px;
  background-color: var(--redClr);
  border-radius: 5px;
}
@media screen and (min-width: 649px) {
  .productMainDiv {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    gap: 40px;
  }
  .productImgSlider {
    width: 290px;
  }
  .productMainSubGrid {
    width: calc(100% - 340px);
  }
  .productSupportDiv {
    align-items: flex-start;
    gap: 20px;
  }
  .profileSupportText {
    font-size: 0.75em;
    text-align: center;
  }
}
@media screen and (min-width: 869px) {
  .productMainDiv {
    margin-top: 15px;
  }
  .productSupportDiv {
    margin-top: 15px;
  }
  .productCartDiv {
    position: unset;
    left: unset;
    bottom: unset;
    box-shadow: none;
    border: 1px solid var(--blackOp12);
    border-radius: 5px;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .productLeftDiv {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid var(--blackOp12);
  }
  .productPriceTitleText {
    display: -webkit-flex;
    display: flex;
  }
  .productCartPriceDiv {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
  .productCartLeftDiv {
    flex-direction: column;
    width: 100%;
  }
  .productTitleDiv {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .productCartTopDiv {
    order: 10;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
  .productImgSlider {
    width: 250px;
  }
  .productMainSubGrid {
    width: calc(100% - 290px);
    grid-template-columns: 1fr 260px;
  }
  .productSupportDiv {
    align-items: flex-start;
    gap: 10px;
    border-top: none;
    padding-top: 10px;
  }
  .productLeftDiv {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
  .percentDiv {
    position: unset;
  }
  .productPercent {
    background-color: transparent;
    color: var(--redClr);
  }
  .productTitleDiv .priceBeforeOff {
    font-size: 0.98em;
    font-weight: 500;
    color: var(--clr3);
    text-decoration: none;
  }
  .priceBeforeOff .tomanText {
    display: block;
    color: var(--clr7);
    font-size: 0.85em;
  }
}
@media screen and (min-width: 1000px) {
  .productImgSlider {
    width: 280px;
  }
  .productMainSubGrid {
    width: calc(100% - 340px);
    grid-template-columns: 1fr 260px;
  }
}
@media screen and (min-width: 1200px) {
  .productImgSlider {
    width: 340px;
  }
  .productMainSubGrid {
    width: calc(100% - 400px);
    grid-template-columns: 1fr 300px;
    gap: 30px;
  }
}
/* descriptionSection */
.descriptionSection {
  margin-top: 20px;
  border-top: 1px solid var(--blackOp12);
  padding-top: 20px;
  gap: 15px;
}
.descriptionTitle {
  font-size: 1.25em;
  font-weight: 500;
  color: var(--clr3);
  gap: 7px;
  align-items: center;
}
/* propsSection */
.propsSection,
.relativeProductSection {
  border-top: 1px solid var(--blackOp12);
  padding-top: 15px;
  margin-top: 25px;
}
.propsSectionTitle,
.relativeProductTitle {
  font-size: 1.25em;
  color: var(--clr3);
  font-weight: 500;
}
.propsSectionList {
  margin-top: 10px;
  gap: 3px;
  padding-right: 30px;
}
.propsSectionItem {
  grid-template-columns: 100px auto 1fr;
  gap: 5px;
  border-bottom: 1px solid var(--blackOp12);
  padding: 10px 0;
}
.propsSectionItem:last-child {
  border-bottom: none;
}
.propsSectionText {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr5);
}
.relativeProductTitle {
  margin: 10px 0;
  gap: 10px;
}
.productSwiper {
  width: 100%;
  padding: 10px;
}
@media screen and (min-width: 968px) {
  .descriptionTitle,
  .relativeProductTitle,
  .propsSectionTitle {
    font-size: 1.4em;
  }
}
.commentBorder {
  height: 1px;
  background-color: var(--blackOp12);
  margin: 30px auto -20px;
}
