.payResult {
  height: 90dvh;
  width: 100%;
}
.payResultIcon {
  height: 180px;
  width: 180px;
}
.payDoneText {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--clr3);
  margin-top: 10px;
}
.payDoneText2 {
  color: var(--clr7);
  font-weight: 500;
  font-size: 0.9em;
  padding: 4px 10px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px dashed var(--clrA);
  background-color: var(--blackOp07);
  transition: background-color 0.4s;
}
.payDoneText2:hover {
  background-color: var(--blackOp10);
}
@media screen and (min-width: 968px) {
  .payResultIcon {
    height: 200px;
    width: 200px;
  }
}
