.profileMainGrid {
  margin-top: 20px;
}
.profileLinkList {
  display: none;
}
.profileDetailsDiv {
  min-height: 50dvh;
}
/* profileSubLoading */
.profileSubLoading {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff7e;
  z-index: 800;
}
.userLoading,
.profileLoading2 {
  height: 70dvh;
}
.profileSubLoadingSpin {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 4px solid var(--blackOp20);
  border-left: 4px solid var(--blackOp20);
  border-bottom: 4px solid transparent;
  border-right: 4px solid transparent;
  animation: spin 2s linear infinite;
}
@media screen and (min-width: 768px) {
  .profileLoading2 {
    height: 100%;
    min-height: 450px;
  }
  .userDesktopLoading {
    grid-template-columns: 300px 1fr;
    gap: 20px;
  }
  .userLoading {
    flex-direction: column;
    margin-top: 10px;
  }
  .profileLoading2 {
    flex-direction: column;
  }
  .userDesktopLoading {
    width: 100%;
    height: 100%;
  }
  .userLoading .profileSubLoadingSpin,
  .profileLoading2 .profileSubLoadingSpin {
    display: none;
  }
  .userDesktopRightDiv,
  .userDesktopLeftDiv {
    border-radius: 7px;
    background-color: var(--blackOp06);
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
/* profileSubMainDiv */
.profileSubMainDiv {
  gap: 20px;
}
.profileForm {
  padding: 15px;
  background-color: var(--bgClr);
  border-radius: 7px;
  box-shadow: 0 0 15px var(--blackOp20);
}
.formFieldSet {
  margin-top: 20px;
}
.customInput {
  height: 40px;
  width: 100%;
  background-color: var(--blackOp06);
  outline: none;
  border: 1px solid var(--blackOp20);
  transition: border 0.4s;
  padding: 15px;
  border-radius: 5px;
  font-size: 0.9em;
}
.birthdayGrid {
  grid-template-columns: 1fr 1fr 2fr;
  gap: 10px;
}
.birthdayGrid input {
  text-align: center;
}
.backLink {
  padding: 5px 0 5px 20px;
  width: fit-content;
}
.customTextArea {
  outline: none;
  border: 1px solid var(--blackOp20);
  background-color: var(--blackOp06);
  border-radius: 5px;
  padding: 10px;
  resize: none;
  height: 180px;
  overflow-y: auto;
  font-size: 0.9em;
}
.red {
  color: var(--redClr);
  font-size: 20px;
  transform: translateY(5px);
}
.submitForm {
  background-color: var(--blueClr1);
  height: 45px;
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
}
.errorText {
  color: var(--redClr);
  padding: 5px 0;
  margin-top: 10px;
}
/* profileHomeNavList */
.profileHomeNavList {
  padding: 15px;
  background-color: var(--bgClr);
  border-radius: 7px;
  box-shadow: 0 0 15px var(--blackOp20);
  gap: 20px;
}
.profileHomeNavItem {
  padding: 10px 0;
  position: relative;
  gap: 7px;
}
.profileHomeNavItem svg {
  color: var(--clr5);
}
.profileHomeNavItem::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--blackOp20);
  position: absolute;
  left: 0;
  bottom: -10px;
  opacity: 0.6;
}
.profileHomeNavItem:last-child::after {
  display: none;
}
.profileClassItem {
  grid-template-columns: auto 1fr;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp20);
  border-radius: 5px;
}
.classDate {
  background-color: var(--blackOp06);
  padding: 15px;
}
.classDate .blue {
  color: var(--blueClr1);
  font-size: 1.4em;
  line-height: 1em;
}
.profileClassInfoDiv {
  padding: 20px 15px;
  flex-wrap: wrap;
  gap: 25px 15px;
}
.classTeacherImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.teacherNameDiv {
  gap: 2px;
}
.classItemDateInfoDiv {
  width: 100%;
  gap: 10px;
}
.classItemDayText,
.classItemDateText {
  padding: 6px 10px 6px 15px;
  border-radius: 5px;
  background-color: rgb(230, 242, 253);
  gap: 5px;
}
.classItemDateText {
  background-color: rgb(231, 247, 230);
}
.cancelClassItem {
  height: 32px;
  width: 32px;
  border-radius: 7px;
  background-color: rgba(255, 99, 71, 0.151);
  color: var(--redClr);
  margin-right: auto;
}
.alertMenuActionDiv {
  margin-top: 20px;
  gap: 10px;
  justify-content: flex-end;
}
.confirmBtn {
  color: var(--bgClr);
  background-color: var(--redClr);
  border-radius: 5px;
  padding: 3px 10px;
}
.cancelAlertBtn {
  background-color: var(--greenClr1);
  color: var(--bgClr);
  border-radius: 5px;
  padding: 3px 10px;
}
/* general profile style */
.profieMainGrid {
  margin-top: 10px;
}
.profileRightSection {
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  padding: 10px;
  border-radius: 5px;
}
.profileNavItem {
  padding: 10px 7px;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.6em;
  color: var(--clr3);
  transition: background-color 0.4s;
  position: relative;
  border-radius: 4px;
}
.profileNavItem::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -5px;
  background-color: var(--blackOp09);
}
.profileNavItem svg {
  color: var(--clr7);
}
.profileNavItem p {
  gap: 7px;
}
.profileNavItem:last-child::after {
  display: none;
}
.profileNavItem:hover {
  background-color: var(--blackOp06);
}
.profileRightNavList {
  display: none;
  gap: 10px;
  margin-top: 5px;
}
.profileHomeHeaderDiv {
  padding: 5px 0 5px;
}
.profileRightSection.subPage {
  display: none;
}
.profileUserInfoDiv {
  gap: 10px;
}
.profileUserName {
  font-size: 1em;
  color: var(--clr3);
  font-weight: 500;
}
.userPhoneNumber {
  font-size: 0.75em;
  font-weight: 500;
  color: var(--clr7);
}
.profileUserLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--clr5);
  color: var(--bgClr);
}
.editUserDataBtn {
  height: 35px;
  padding: 0 10px;
  border-radius: 5px;
  gap: 5px;
  font-size: 0.75em;
  font-weight: 500;
  color: var(--blueClr2);
  transition: background-color 0.4s;
}
.editUserDataBtn svg {
  height: 16px;
  width: 16px;
}
.editUserDataBtn:hover {
  background-color: var(--blueClrOp1);
}
.profileNavItem * {
  cursor: pointer;
}
.profileRightSection .profileNavItem.active {
  position: relative;
}
.profileRightSection .profileNavItem.active::before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  right: -12px;
  top: 0;
  background-color: var(--redClr);
}
@media screen and (min-width: 968px) {
  .profieMainGrid {
    grid-template-columns: 300px 1fr;
    gap: 20px;
    margin-top: 10px;
  }
  .profileRightSection {
    height: fit-content;
    padding: 12px 12px 10px 12px;
  }
  .profileRightSection.subPage {
    display: -webkit-flex;
    display: flex;
  }
  .profileRightNavList {
    display: -webkit-flex;
    display: flex;
    position: relative;
  }
  .profileRightNavList::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--blackOp09);
    position: absolute;
    left: 0;
    top: -5px;
  }
  .editUserDataBtn span {
    display: none;
  }
  .profileHomeHeaderDiv {
    margin-bottom: 10px;
  }
  .profileHomeHeaderDiv.subPage {
    display: -webkit-flex;
    display: flex;
  }
}
@media screen and (min-width: 1250px) {
  .profieMainGrid {
    grid-template-columns: 310px 1fr;
    gap: 25px;
  }
}
/* home */
.profileHomeStateGrid {
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
}
.profileHomeStateItem {
  border-left: 1px solid var(--blackOp12);
  padding: 5px 0;
  transition: background-color 0.4s;
}
.profileHomeStateItem:last-child {
  border-left: none;
}
.profileHomeStateItem:hover {
  background-color: var(--blackOp06);
}
.profileHomeStateItem * {
  pointer-events: none;
}
.profileHomeStateText {
  font-size: 0.85em;
  font-weight: 500;
  color: var(--clr3);
  margin-top: 20px;
}
.profileHomeStateNumber {
  font-size: 0.8em;
  height: 22px;
  width: 22px;
  background-color: var(--bgClr);
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-60%, 60%);
  box-shadow: 0 0 5px var(--blackOp20);
}
.profileHomeStateIcon {
  height: 35px;
  width: 35px;
}
.profileHomeNavs {
  margin-top: 30px;
  gap: 10px;
}
@media screen and (min-width: 450px) {
  .profileHomeStateText {
    font-size: 0.9em;
  }
  .profileHomeStateNumber {
    font-size: 0.9em;
    height: 28px;
    width: 28px;
    transform: translate(-50%, 50%);
  }
  .profileHomeStateItem {
    padding: 20px 0;
  }
  .profileHomeStateIcon {
    height: 42px;
    width: 42px;
  }
}
@media screen and (min-width: 649px) {
  .profileHomeStateText {
    font-size: 1em;
  }
  .profileHomeStateNumber {
    font-size: 1em;
    height: 32px;
    width: 32px;
  }
  .profileHomeStateItem {
    padding: 20px 0;
  }
  .profileHomeStateIcon {
    height: 55px;
    width: 55px;
  }
}
@media screen and (min-width: 968px) {
  .profileHomeStateText {
    font-size: 1.1em;
    margin-top: 25px;
  }
  .profileHomeStateIcon {
    height: 70px;
    width: 70px;
  }
  .profileHomeNavs {
    display: none;
  }
}
/* profile class */
.profileClassList {
  gap: 10px;
}
