.cartPageLoadingSection {
  margin-top: 30px;
  gap: 20px;
}
.cartPageDetailsLoading,
.cartPageSummaryLoading {
  width: 100%;
  height: 450px;
  border-radius: 10px;
  background-color: var(--blackOp06);
  overflow: hidden;
}
.cartPageSummaryLoading {
  height: 250px;
}
@media screen and (min-width: 768px) {
  .cartPageLoadingSection {
    grid-template-columns: 1fr 250px;
  }
}
@media screen and (min-width: 768px) {
  .cartPageLoadingSection {
    grid-template-columns: 1fr 300px;
  }
}
/* cartPageMainSection */
.cartPageMainSection {
  margin-top: 20px;
  gap: 20px;
}
.cartPageRightDiv {
  padding: 20px;
  border-radius: 7px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  gap: 20px;
}
.cartPageDetailsList {
  gap: 40px;
}
.cartDetailsItem {
  grid-template-columns: 150px 1fr;
  gap: 10px;
  position: relative;
}
.cartDetailsItem::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--blackOp12);
  position: absolute;
  left: 0;
  bottom: -20px;
  opacity: 0.8;
}
.cartDetailsItem:last-child::after {
  display: none;
}
.cartItemImg {
  width: 100%;
  height: auto;
  aspect-ratio: 1.5/1;
  border-radius: 7px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.cartItemInfoDiv {
  gap: 5px;
}
.cartItemInfoLink {
  font-size: 1.05em;
  margin-bottom: 10px;
}
.cartItemDiscountPercent {
  background-color: var(--redClr);
  border-radius: 12px;
  gap: 5px;
  color: var(--bgClr);
  padding: 1px 7px 1px 9px;
  font-size: 0.8em;
}
.cartItemBeforeDiscountPrice {
  font-size: 0.95em;
  position: relative;
  color: var(--clr9);
}
.cartItemBeforeDiscountPrice::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: var(--redClr);
  transform: translateY(-50%);
  opacity: 0.6;
}
.cartItemFinalPrice {
  font-size: 1em;
  margin-right: auto;
  color: var(--clr5);
}
.cartItemFinalPrice span {
  font-size: 0.85em;
  color: var(--clr9);
  margin-right: 5px;
}
.cartItemRemoveBtn {
  color: var(--redClr);
  height: 35px;
  gap: 7px;
  width: fit-content;
  padding: 0 10px;
  font-size: 0.85em;
  border-radius: 7px;
  background-color: var(--blackOp06);
}
.cartPageTitle {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--clr3);
}
.cartItemRemoveBtn svg {
  width: 15px;
  height: 15px;
  transform: translateY(-1px);
}
.cartItemDiscountDiv.hide {
  opacity: 0;
}
.cartItemManageDiv {
  margin-top: 20px;
  border-top: 1px solid var(--blackOp12);
  padding-top: 20px;
}
.cartDetailsItem .productCartManageDiv {
  grid-template-columns: 30px 80px 30px;
}
/* cartPageLeftDiv */
.cartPageLeftDiv {
  background-color: var(--bgClr);
  padding: 15px;
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 7px;
  gap: 10px;
}
.dicountDiv {
  gap: 10px;
}
.discountCodeInput {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--blackOp20);
  padding: 0 15px 0 70px;
  transition: border 0.4s;
  direction: ltr;
}
.discountCodeInput:focus {
  border: 1px solid var(--blackOp44);
}
.discountCodeInput:placeholder-shown {
  direction: rtl;
}
.submitDiscountBtn {
  position: absolute;
  left: 0;
  height: 100%;
  width: 60px;
  color: var(--blueClr1);
  transition: opacity 0.4s;
  background-color: var(--blackOp06);
  border-radius: 7px 0 0 7px;
}
.discountCodeInput:placeholder-shown + .submitDiscountBtn {
  opacity: 0.7;
  pointer-events: none;
  background-color: transparent;
}
.submitDiscountBtn.waiting {
  pointer-events: none;
}
.submitDiscountBtn.waiting .text {
  display: none;
}
.submitDiscountBtn .waitingSpan {
  display: none;
}
.submitDiscountBtn.waiting .waitingSpan {
  display: block;
}
.waitingSpan {
  height: 15px;
  width: 15px;
  border-right: 2px solid var(--blackOp44);
  border-top: 2px solid var(--blackOp44);
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  animation: spin 1s linear infinite;
  border-radius: 50%;
}
.discountErrorText {
  color: var(--redClr);
}
.successCode {
  gap: 7px;
  color: var(--greenClr1);
}
.submitCartLink {
  background-color: var(--blueClr1);
  height: 40px;
  border-radius: 7px;
  margin-top: 10px;
}
.summaryPriceText {
  font-size: 1em;
  font-weight: 500;
  color: var(--clr3);
}
.summaryPriceText span {
  color: var(--clr9);
  font-size: 0.8em;
}
@media screen and (min-width: 550px) {
  .cartDetailsItem {
    grid-template-columns: 150px 1fr auto;
    gap: 20px;
  }
  .cartItemRemoveBtn {
    align-self: center;
  }
}
@media screen and (min-width: 768px) {
  .cartPageMainSection {
    grid-template-columns: 1fr 280px;
    margin-top: 10px;
  }
  .cartDetailsItem {
    grid-template-columns: 150px 1fr;
  }
  .cartItemRemoveBtn {
    align-self: unset;
  }
  .cartPageLeftDiv {
    height: fit-content;
  }
}
@media screen and (min-width: 1050px) {
  .cartPageMainSection {
    grid-template-columns: 1fr 320px;
    gap: 20px;
  }
  .cartDetailsItem {
    grid-template-columns: 190px 1fr auto;
    gap: 20px;
  }
  .cartItemRemoveBtn {
    align-self: center;
    margin-right: 10px;
  }
}
/* emptyCartSection */
.emptyCartSection {
  min-height: 70dvh;
}
.emptyCartIcon {
  height: 150px;
  width: auto;
  color: var(--blackOp44);
}
.emptyCartTitle {
  font-size: 1.4em;
  margin-top: 10px;
}
