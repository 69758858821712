.uploadVideoForm {
  padding: 15px;
  background-color: var(--bgClr);
  box-shadow: 0 0 15px var(--blackOp12);
  border-radius: 5px;
  gap: 10px;
  display: none;
}
.uploadVideoForm.showForm {
  display: -webkit-flex;
  display: flex;
}
.uploadFieldset {
  gap: 10px;
  transition: background-color 0.4s;
}
.profileUploadIcon {
  height: 180px;
  width: 180px;
  opacity: 0.8;
}
.uploadTitle {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--clr3);
}
.uploadFieldset {
  border: 1px dashed var(--blackOp44);
  padding: 25px 15px 30px 15px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  background-color: var(--blackOp07);
}
.profileUploadText {
  font-size: 0.95em;
  color: var(--clr5);
  text-align: center;
  margin-top: 15px;
  padding: 0 10px;
}
.uploadFileInput {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.uploadFieldset.done {
  border: 1px dashed var(--greenClr1);
  background-color: #00800008;
}
.customLabel {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr5);
}
.profileUploadFormGrid {
  gap: 20px;
}
.profileUploadInputsDiv {
  gap: 20px;
}
.profileUploadEmptyIcon {
  height: 70px;
  width: 70px;
  position: absolute;
  animation: filemove 0.4s linear alternate;
  display: none;
  left: 47%;
  top: 60%;
  transform: translate(-50%, -50%);
}
.profileUploadEmptyIcon.showIcon {
  display: -webkit-flex;
  display: flex;
}
.hashTagDiv {
  margin-top: 15px;
}
.addHashTagBtn {
  position: absolute;
  left: 5px;
  top: 31px;
  background-color: var(--greenClr1);
  height: 30px;
  color: var(--bgClr);
  padding: 0 15px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
  transition: opacity 0.4s;
}
.addHashTagBtn:hover {
  opacity: 0.8;
}
.tagsList {
  margin-top: 15px;
  flex-wrap: wrap;
}
.tagItemBtn {
  padding: 5px;
  border: 1px solid var(--blackOp20);
  border-radius: 4px;
  background-color: var(--blackOp07);
  color: var(--clr5);
  font-size: 0.85em;
  font-weight: 500;
}
.removeTagSpan {
  border-radius: 3px;
  color: var(--bgClr);
  background-color: var(--redClr);
  height: 20px;
  width: 20px;
}
.profileErrorText {
  color: var(--redClr);
  font-size: 0.8em;
  font-weight: 500;
  position: relative;
  padding-right: 10px;
}
.profileErrorText::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--redClr);
  position: absolute;
  right: 0;
  top: 6px;
  opacity: 0.7;
}
.profileUploadSizeText {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--clr5);
}
.submitFormBtn {
  border-radius: 7px;
  background-color: var(--blueClr2);
  font-size: 0.9em;
  font-weight: 500;
  color: var(--bgClr);
  height: 40px;
  margin-top: 5px;
}
.errorField {
  border-color: var(--redClr);
  background-color: #ff634707;
}
@keyframes filemove {
  from {
    transform: translate(-50%, -80%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  .submitFormBtn {
    margin: 5px auto 0 0;
    width: 100%;
    max-width: 200px;
  }
}
@media screen and (min-width: 1100px) {
  .profileUploadFormGrid {
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
  }
  .profileUploadHolderDiv {
    order: 2;
    margin: 25px 0 0;
    height: calc(100% - 25px);
  }
  .uploadFieldset {
    height: 100%;
  }
  .hashTagDiv {
    max-width: calc(50% - 7px);
  }
}
/* uploadingDiv */
.uploadingDiv {
  width: 100%;
  height: 100%;
  display: none;
  background-color: var(--bgClr);
  border-radius: 12px;
  box-shadow: 0 0 15px var(--blackOp12);
  padding: 20px;
}
.uploadingDiv.showUploadProgress {
  display: -webkit-flex;
  display: flex;
}
.uploadContentDiv {
  width: 94%;
  max-width: 400px;
  padding: 20px;
  margin: 0 auto;
}
.uploadProgressDiv {
  position: relative;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background-color: var(--blackOp09);
  margin: 0 auto;
  overflow: hidden;
}
.uploadingText {
  margin-top: 25px;
  font-size: 1em;
  font-weight: 500;
  color: var(--clr3);
  text-align: center;
}
.uploadingText2 {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr5);
  text-align: center;
}
.uploadWave1 {
  position: absolute;
  width: 120%;
  left: -10%;
  transition: transform 1s;
  top: 100%;
  animation: wave 1.5s linear infinite alternate;
  z-index: 10;
  opacity: 0.8;
}
.uploadWave2 {
  position: absolute;
  width: 120%;
  left: -10%;
  transition: transform 1s;
  top: 95%;
  animation: wave2 2s linear infinite alternate-reverse;
  z-index: 8;
  opacity: 0.4;
}
.uploadPercentText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #ffffff7e;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--clr3);
  z-index: 100;
}
.cancelUploadBtn {
  width: fit-content;
  height: 35px;
  padding: 0 25px;
  border-radius: 7px;
  background-color: var(--redClr);
  color: var(--bgClr);
  font-size: 0.95em;
  font-weight: 500;
  margin: 10px auto 0 0;
  transition: filter 0.4s;
}
.cancelUploadBtn:hover {
  filter: contrast(0.8);
}
@keyframes wave {
  from {
    left: -20%;
  }
  to {
    left: 0;
  }
}
@keyframes wave2 {
  from {
    left: 0;
  }
  to {
    left: -20%;
  }
}
