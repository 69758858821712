/* blogDetailsLoadingSection */
.blogDetailsLoadingSection {
  margin-top: 30px;
}
.loadingBlogInfo {
  width: 100%;
  height: 220px;
  border-radius: 7px;
  background-color: var(--blackOp06);
  overflow: hidden;
  margin-top: 20px;
}
.blogNavLoading {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  background-color: var(--blackOp06);
  overflow: hidden;
  max-width: 500px;
}
.loadingBlogContent {
  width: 100%;
  border-radius: 7px;
  background-color: var(--blackOp06);
  overflow: hidden;
  height: 250px;
  margin-top: 20px;
}
/* blogMainContent */
.blogMainContent {
  margin-top: 20px;
  gap: 20px;
}
.shareDiv {
  padding: 10px;
  background-color: var(--blackOp06);
  border-radius: 7px;
  margin-top: 20px;
}
.shareSocailItem {
  color: var(--clr5);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: var(--blackOp07);
}
@media screen and (min-width: 768px) {
  .blogMainContent {
    grid-template-columns: 1fr 300px;
    margin-top: 10px;
    gap: 30px;
  }
}
@media screen and (min-width: 968px) {
  .blogMainContent {
    grid-template-columns: 1fr 320px;
    gap: 50px;
  }
}
.blogMainImg {
  border-radius: 7px;
  width: 100%;
  aspect-ratio: 2/1.1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.blogDetailsInfoDiv {
  padding: 10px;
}
.blogShortDesc {
  text-align: justify;
  margin-top: 5px;
  line-height: 1.6em;
}
.blogIconDiv {
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.blogIconItem {
  padding: 4px 9px 4px 7px;
  background-color: var(--blackOp06);
  border-radius: 5px;
  gap: 5px;
  color: var(--clr5);
}
.blogIconItem svg {
  margin-top: -2px;
  width: 17px;
  height: 17px;
}
@media screen and (min-width: 968px) {
  .blogDetailsInfo {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .blogDetailsInfoDiv {
    height: 100%;
    padding: 10px 0 0;
  }
  .blogIconDiv {
    margin-top: auto;
  }
}
/* blogsContent */
.blogsContent {
  margin-top: 20px;
  padding: 0 15px;
}
.blogsContent p,
.blogsContent a,
.blogsContent span {
  font-size: 0.92em;
}
.blogsContent a {
  border-bottom: 1px solid var(--blueClr1);
  color: var(--blueClr1);
}
.blogsContent h1 {
  font-size: 1.35em;
}
.blogsContent h2 {
  font-size: 1.2em;
}
.blogsContent h3,
.blogsContent h4,
.blogsContent h5,
.blogsContent h6 {
  font-size: 1.05em;
}
@media screen and (min-width: 550px) {
  .blogsContent p,
  .blogsContent a,
  .blogsContent span {
    font-size: 0.95em;
  }
  .blogsContent h3,
  .blogsContent h4,
  .blogsContent h5,
  .blogsContent h6 {
    font-size: 1.08em;
  }
  .blogsContent h2 {
    font-size: 1.25em;
  }
  .blogsContent h1 {
    font-size: 1.45em;
  }
}
@media screen and (min-width: 768px) {
  .blogsContent p,
  .blogsContent a,
  .blogsContent span {
    font-size: 1em;
  }
  .blogsContent h3,
  .blogsContent h4,
  .blogsContent h5,
  .blogsContent h6 {
    font-size: 1.2em;
  }
  .blogsContent h2 {
    font-size: 1.4em;
  }
  .blogsContent h1 {
    font-size: 1.6em;
  }
  .blogsContent {
    padding: 0;
  }
}
/* blogMainLeftDiv */
.blogMainLeftDiv {
  margin-top: 20px;
}
.tagsDiv {
  padding: 15px;
  gap: 10px;
  background-color: var(--blackOp06);
  border-radius: 7px;
  border: 1px solid var(--blackOp20);
  margin: 0 15px;
}
.blogCatsList {
  flex-wrap: wrap;
  gap: 10px;
}
.suggestBlogsDiv {
  padding: 0 15px;
  margin-top: 30px;
}
.suggestBlogsList {
  gap: 20px;
  margin-top: 20px;
}
.suggestBlogItem {
  grid-template-columns: 140px 1fr;
  gap: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--blackOp12);
}
.suggestBlogItem:last-child {
  border-bottom: none;
}
.suggestBlogImage {
  width: 100%;
  aspect-ratio: 2/1.2;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  margin-bottom: 5px;
  border-radius: 7px;
}
.suggestBlogInfoDiv {
  border-radius: 7px 7px 0 0;
  padding-top: 10px;
}
.suggestBlogTitle {
  width: fit-content;
}
@media screen and (min-width: 768px) {
  .blogMainLeftDiv {
    margin-top: 0;
    padding: 0;
    border-top: none;
  }
  .tagsDiv {
    margin: 0;
  }
  .suggestBlogsDiv {
    padding: 0;
  }
  .suggestBlogItem {
    border-radius: 0;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none;
  }
}
