.podcastItemDiv {
  border-radius: 10px;
  overflow: hidden;
}
.podcastImg {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.podcastItemInfoDiv {
  padding: 0 10px 10px;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--blackOp12);
}
.podcastName {
  margin-top: 10px;
}
.podcastIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff70;
}
.podcastIcon svg {
  height: 17px;
  width: 17px;
  color: #000000c7;
}
.podcastDuration {
  color: var(--clr7);
  gap: 5px;
  margin-top: 3px;
}
.podcastPriceDiv {
  justify-content: flex-end;
  padding: 0 5px;
  gap: 10px;
  margin-top: 10px;
}
.podcastPriceText {
  gap: 6px;
}
.podcastPriceText span {
  transform: translateY(1px);
}
.podcastFreeText,
.podcastRealPriceText {
  margin: 10px auto 0 5px;
  gap: 6px;
}
.podcastFreeText {
  color: var(--greenClr1);
}
.podcastDicountValue {
  text-decoration: line-through;
  text-decoration-color: var(--redClr);
}
.podcastDicountPercent {
  background-color: var(--redClr);
  position: absolute;
  left: 5px;
  top: 7px;
  padding: 2px 10px 0;
  color: var(--bgClr);
  border-radius: 20px;
}
.podcastDicountPercent svg {
  width: 12px;
  height: 12px;
  transform: translateY(-1px);
}
