@font-face {
  font-family: "Yekan";
  src: url("/public/font/yekan4.woff") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Yekan";
  src: url("/public/font//yekan5.woff") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Yekan";
  src: url("/public/font//yekan6.woff") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Yekan";
  src: url("/public/font//yekan7.woff") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
:root {
  --bgClr: #fff;
  --clr3: #333;
  --clr5: #555;
  --clr7: #777;
  --clr9: #999;
  --clrA: #aaa;
  --clrE: #eee;
  --clrWhite: #fcfcfc;
  --whiteClrOp60: #ffffff60;
  --blueClr1: #4072fc;
  --blueClr2: #0a87ec;
  --blueClr3: #6cbdff;
  --blueClr4: #e2f2ff;
  --blueClrOp1: #8aaaf031;
  --blueClrOp2: #201cf325;
  --blueClrOp3: #201cf341;
  --blueClrOp4: #201cf370;
  --blueClrOp5: #8aaaf01c;
  --blackOp06: #00000006;
  --blackOp07: #00000007;
  --blackOp09: #00000009;
  --blackOp12: #00000012;
  --blackOp20: #00000020;
  --blackOp44: #00000044;
  --blackOp85: #00000085;
  --blackOpB2: #000000b2;
  --blackOpD2: #000000d2;
  --redClr: tomato;
  --greenClr1: rgb(42, 184, 42);
  --mainClr1: #1c608d;
  --mainClr2: #8b716c;
  --mainClr3: #c3b8b2;
  --mainClr4: #224c58;
}
body,
html {
  background-color: var(--bgClr);
  max-width: 100vw;
  overflow-x: clip;
  direction: rtl;
  scroll-behavior: smooth;
  min-width: 300px;
}
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background: #ccc;
}
body::-webkit-scrollbar-thumb:hover,
html::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.hideOverflow {
  overflow-y: hidden;
}
a {
  text-decoration: none;
  cursor: pointer;
}
button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: right;
}
img {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
p {
  cursor: default;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.container {
  width: 94%;
  margin: 0 auto;
  max-width: 1350px;
}
.flex {
  display: -webkit-flex;
  display: flex;
}
.grid {
  display: -moz-grid;
  display: -ms-grid;
  display: grid;
}
.flexCol {
  flex-direction: column;
}
.alignCenter {
  align-items: center;
}
.justifyBetween {
  justify-content: space-between;
}
.flexCenter {
  align-items: center;
  justify-content: center;
}
.gap5 {
  gap: 5px;
}
.gap10 {
  gap: 10px;
}
.gap20 {
  gap: 20px;
}
.mar5 {
  margin-top: 5px;
}
.mar10 {
  margin-top: 10px;
}
.mar20 {
  margin-top: 20px;
}
.rel {
  position: relative;
}
.faFont {
  font-family: "Yekan", Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.hideText {
  color: transparent;
  font-size: 0em;
}
.hideScrollBar::-webkit-scrollbar {
  display: none;
}
.hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.customScrollbar::-webkit-scrollbar {
  width: 7px;
}
.customScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
}
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.customFieldSet {
  outline: none;
  border: none;
  gap: 5px;
}
.textClr3 {
  color: var(--clr3);
}
.textClr5 {
  color: var(--clr5);
}
.textClr7 {
  color: var(--clr7);
}
.textClrF {
  color: var(--bgClr);
}
.textSize8 {
  font-size: 0.75em;
}
.textSize9 {
  font-size: 0.8em;
}
.textSize1 {
  font-size: 0.9em;
}
.textSize11 {
  font-size: 1em;
}
.textSize12 {
  font-size: 1.05em;
}
.textSize125 {
  font-size: 1.1em;
}
.textSize14 {
  font-size: 1.2em;
}
.textSize16 {
  font-size: 1.35em;
}
.justifyText {
  text-align: justify;
}
@media screen and (min-width: 550px) {
  .textSize8 {
    font-size: 0.77em;
  }
  .textSize9 {
    font-size: 0.85em;
  }
  .textSize1 {
    font-size: 0.95em;
  }
  .textSize11 {
    font-size: 1.05em;
  }
  .textSize125 {
    font-size: 1.12em;
  }
  .textSize12 {
    font-size: 1.08em;
  }
  .textSize14 {
    font-size: 1.25em;
  }
  .textSize16 {
    font-size: 1.45em;
  }
}
@media screen and (min-width: 768px) {
  .textSize8 {
    font-size: 0.8em;
  }
  .textSize9 {
    font-size: 0.9em;
  }
  .textSize1 {
    font-size: 1em;
  }
  .textSize11 {
    font-size: 1.1em;
  }
  .textSize125 {
    font-size: 1.25em;
  }
  .textSize12 {
    font-size: 1.2em;
  }
  .textSize14 {
    font-size: 1.4em;
  }
  .textSize16 {
    font-size: 1.6em;
  }
}
/* styles */
.loaderItem::before {
  content: "";
  width: 40%;
  height: 100%;
  position: absolute;
  animation: load 1s linear infinite reverse;
  background-image: linear-gradient(
    to right,
    transparent,
    var(--blackOp06),
    transparent
  );
}
.logoText {
  color: var(--blueClr2);
  gap: 7px;
  font-size: 1em;
}
.logoText svg {
  height: 20px;
  width: 20px;
}
/* modalSection */
.modalSection {
  position: fixed;
  left: 50%;
  top: 60px;
  background-color: var(--bgClr);
  border-radius: 7px;
  padding: 30px 15px;
  z-index: 1000;
  width: 90%;
  height: 40px;
  transform: translate(-50%, 40px);
  max-width: 550px;
  pointer-events: none;
  transition: transform 0.3s, opacity 0.3s;
  gap: 10px;
  box-shadow: 0 0 15px var(--blackOp44);
  opacity: 0;
}
.modalSection.show {
  transform: translate(-50%, 0);
  opacity: 1;
}
.modalSection svg {
  color: var(--blueClr2);
}
@keyframes load {
  from {
    right: -40%;
  }
  to {
    right: 140%;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* htmlContent */
.content * {
  text-align: justify;
}
.content p,
.content h1,
.content h4,
.content h3,
.content h5,
.content h6,
.content span {
  color: var(--clr5);
  font-weight: 500;
}
.content a {
  color: var(--blueClr2);
  border-bottom: 1px solid var(--blueClr2);
  width: fit-content;
}
.content > div {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.showFullDescription {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  height: 35px;
  align-items: center;
  z-index: 102;
  gap: 5px;
  padding: 0 10px;
  border: 1px solid var(--clrA);
  border-radius: 7px;
  background-color: var(--bgClr);
  transition: background-color 0.4s;
}
.showFullDescription:hover {
  background-color: var(--clrWhite);
}
.podcastDescriptionDiv.full {
  padding-bottom: 30px;
}
.contentDescDiv.full .showFullDescription,
.contentDescDiv.full .content:after {
  display: none;
}
.contentDescDiv.full .contentList {
  max-height: unset;
}
.contentList {
  max-height: 150px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}
.contentList::after {
  content: "";
  height: 120px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #fff, #ffffffde, transparent);
  z-index: 10;
}
.contentDescDiv .showFullDescription {
  bottom: 10px;
}
.contentList p,
.contentList a,
.contentList span {
  font-size: 0.92em;
}
.contentList a {
  border-bottom: 1px solid var(--blueClr1);
  color: var(--blueClr1);
}
.contentList h1 {
  font-size: 1.35em;
}
.contentList h2 {
  font-size: 1.2em;
}
.contentList h3,
.contentList h4,
.contentList h5,
.contentList h6 {
  font-size: 1.05em;
}
@media screen and (min-width: 550px) {
  .contentList p,
  .contentList a,
  .contentList span {
    font-size: 0.95em;
  }
  .contentList h3,
  .contentList h4,
  .contentList h5,
  .contentList h6 {
    font-size: 1.08em;
  }
  .contentList h2 {
    font-size: 1.25em;
  }
  .contentList h1 {
    font-size: 1.45em;
  }
}
@media screen and (min-width: 768px) {
  .contentList p,
  .contentList a,
  .contentList span {
    font-size: 1em;
  }
  .contentList h3,
  .contentList h4,
  .contentList h5,
  .contentList h6 {
    font-size: 1.2em;
  }
  .contentList h2 {
    font-size: 1.4em;
  }
  .contentList h1 {
    font-size: 1.6em;
  }
}
.hoverMsg {
  position: absolute;
  left: 50%;
  top: 0;
  padding: 3px 10px;
  background-color: var(--clr7);
  color: var(--bgClr);
  border-radius: 5px;
  white-space: nowrap;
  transform: translate(-50%, -140%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
}
.hoverMsg::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--clr7);
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
}
.hoverText:hover .hoverMsg {
  opacity: 1;
}
/* alertMenuSection */
.alertMenuSection {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background-color: var(--blackOp85);
  z-index: 1100;
  cursor: pointer;
}
.alertMenuDiv {
  width: 90%;
  max-width: 550px;
  background-color: var(--bgClr);
  cursor: default;
  border-radius: 5px;
  box-shadow: 0 0 15px var(--blackOp44);
  overflow: hidden;
}
.alertMenuHeaderDiv {
  background-color: var(--redClr);
  padding: 12px 15px;
}
.closeAlertMenuBtn {
  font-size: 19px;
  color: var(--bgClr);
}
.alertMenuContent {
  background-color: var(--bgClr);
  padding: 15px;
}
/* emptyOrderList */
.emptyOrderList {
  height: 50vw;
  width: 100%;
}
.profileEmptyImg {
  height: 150px;
  width: 150px;
  opacity: 0.7;
}
.profileEmptyText {
  font-size: 0.98em;
  font-weight: 500;
  color: var(--clr3);
  margin-top: 25px;
}
.emptyLink {
  margin-top: 10px;
  color: var(--clr5);
  background-color: var(--blackOp09);
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
  gap: 5px;
  font-size: 0.85em;
  border: 1px dashed var(--blackOp44);
}
.emptyLink svg {
  height: 20px;
  width: 20px;
}
@media screen and (min-width: 768px) {
  .profileEmptyText {
    font-size: 1.01em;
  }
  .emptyLink {
    font-size: 0.95em;
  }
}
