.storyContainer {
  margin-top: 30px;
}
.storySwiper {
  width: 100%;
}
.storyItem {
  cursor: pointer;
}
.storyImgDiv {
  border-radius: 50%;
  padding: 5px;
  border: 3px solid rgba(255, 99, 71, 0.288);
}
.storyImgDiv.played {
  border: 3px solid transparent;
}
.storyCoverImg {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.storyTitle {
  text-align: center;
  white-space: wrap;
  margin-top: 5px;
}
/* storyplay */
.storyVideoSection {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background-color: var(--blackOpD2);
  z-index: 1000;
  display: none;
}
.storyVideoSection.active {
  display: -webkit-flex;
  display: flex;
}
.storyVideoMainDiv {
  height: 90dvh;
  width: auto;
  position: relative;
  aspect-ratio: 1/1.8;
}
.storyVideo {
  height: 100%;
  aspect-ratio: 1/1.8;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.closeStoryVideoBtn {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 35px;
  width: 35px;
  border-radius: 7px;
  background-color: var(--blackOp44);
  color: var(--bgClr);
  font-size: 1.1em;
  z-index: 20;
}
.storyTextDiv {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    to top,
    #0000008f calc(100% - 25px),
    transparent
  );
  padding: 20px 15px 30px;
  max-width: 100%;
  transition: opacity 0.4s;
  z-index: 0;
}
.storyLink {
  font-size: 0.8em;
  color: tomato;
  border-bottom: 1px solid tomato;
  width: fit-content;
  line-height: 0.9em;
  margin: 10px 0 7px;
}
.storyVideoMainDiv.play .storyTextDiv {
  opacity: 0;
}
.storyVideoMainDiv::after {
  content: "";
  height: 20%;
  width: 100%;
  position: absolute;
}
.storyNameText {
  padding: 30px 0 0;
  text-align: justify;
}
.progressDiv {
  position: absolute;
  left: 5%;
  bottom: 20px;
  width: 90%;
  height: 3px;
  direction: ltr;
}
.timeDiv {
  position: absolute;
  left: 4%;
  bottom: 30px;
  z-index: 1001;
  background-color: var(--blackOp44);
  padding: 3px 10px;
  border-radius: 5px;
}
.progressBarInput {
  height: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% + 10px);
  position: absolute;
  left: -5px;
  background-color: transparent;
  outline: none;
  border-radius: 7px;
  accent-color: var(--blueClr1);
  position: absolute;
  bottom: 0;
  transform: translateY(40%);
  z-index: 10;
}
.timePassed {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: var(--blueClr1);
  opacity: 0.8;
  z-index: 9;
}
.nextVideoBtn svg,
.preVideoBtn svg {
  display: none;
}
.nextVideoBtn {
  position: absolute;
  width: 20%;
  height: 100%;
  border-radius: 200% 0 0 200%;
  top: 0;
  right: 0;
}
.preVideoBtn {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  border-radius: 0 200% 200% 0;
}
.stroryPlayBtn {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.playIconDiv {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -35px 0 0 -35px;
  background-image: -moz-radial-gradient(var(--bgClr), #ffffff7c, transparent);
  background-image: -webkit-radial-gradient(
    var(--bgClr),
    #ffffff7c,
    transparent
  );
  background-image: radial-gradient(var(--bgClr), #ffffff7c, transparent);
  pointer-events: none;
  transition: transform 0.4s, opacity 0.4s;
}
.playIconDiv svg {
  height: 50px;
  width: 50px;
  color: var(--bgClr);
}
.storyVideoMainDiv.pause .playIconDiv {
  opacity: 0;
  transform: scale(1.6);
}
.storyVideo {
  cursor: pointer;
}
.totalProgressDiv {
  height: 5px;
  width: 92%;
  background-color: #ccc;
  border-radius: 20px;
  margin: 15px auto 0;
  overflow: hidden;
  position: absolute;
  left: 4%;
  bottom: 15px;
  z-index: 1002;
  cursor: pointer;
}
.storyProgressDiv {
  background-color: var(--blueClr1);
  border-radius: 50px;
  height: 100%;
  width: 0;
  left: 0;
  top: 0;
  position: absolute;
  pointer-events: none;
}
.totalTime {
  color: #ccc;
  font-size: 0.75em;
  direction: ltr;
}
@media screen and (min-width: 650px) {
  .nextVideoBtn {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 50%;
    transform: translate(110%, -50%);
  }
  .preVideoBtn {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 50%;
    transform: translate(-110%, -50%);
  }
  .nextVideoBtn svg,
  .preVideoBtn svg {
    display: block;
    color: var(--bgClr);
    font-size: 2em;
  }
}
