.homeLoadingMainDiv {
  height: calc(100dvh - 220px);
}
.mobileSpin {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-right: 4px solid var(--blackOp20);
  border-top: 4px solid var(--blackOp20);
  border-left: 4px solid var(--blackOp06);
  border-bottom: 4px solid var(--blackOp06);
  animation: spin 2s linear infinite;
}
.mobileLoading {
  height: 100%;
}
.mobileLoadingItemPerant {
  width: 50%;
  height: 10px;
  border-radius: 50px;
  overflow: hidden;
  margin-top: 20px;
  max-width: 350px;
}
.mobileLoadingItem {
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--blueClrOp1);
  animation: mobileAnim 1s linear infinite alternate-reverse;
  border-radius: 50px;
}
.mobileLoadingItem2 {
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--blueClrOp2);
  animation: mobileAnim2 1.2s linear infinite alternate;
  border-radius: 50px;
}
@keyframes mobileAnim {
  0% {
    left: 0;
    width: 150px;
  }
  50% {
    left: calc(50% - 90px);
    width: 180px;
  }
  100% {
    width: 120px;
    left: calc(100% - 120px);
  }
}
@keyframes mobileAnim2 {
  0% {
    right: 0%;
    width: 100px;
  }
  100% {
    right: calc(100% - 140px);
    width: 140px;
  }
}
@media screen and (min-width: 768px) {
  .mobileLoading {
    display: none;
  }
}
/* desktopLoadingMainDiv */
.desktopLoadingMainDiv {
  display: none;
}
@media screen and (min-width: 868px) {
  .homeLoadingMainDiv {
    height: fit-content;
  }
  .desktopLoadingMainDiv {
    display: -webkit-flex;
    display: flex;
    height: 100%;
  }
  .sliderLoading {
    width: 100%;
    height: 400px;
    background-color: var(--blackOp07);
    overflow: hidden;
  }
  .storySection {
    margin-top: 30px;
    padding-bottom: 30px;
    flex-wrap: nowrap;
    gap: 10px;
    overflow: hidden;
  }
  .loadingStoryItem {
    border-radius: 50%;
    min-width: calc(100% / 8 - 10px);
    aspect-ratio: 1/1;
    background-color: var(--blackOp07);
    overflow: hidden;
  }
  .loadingStoryItem .loaderItem::before {
    width: 60%;
  }
  .middleBannerLoadingItem {
    height: 180px;
    width: 100%;
    background-color: var(--blackOp07);
    border-radius: 5px;
    overflow: hidden;
  }
}
@media screen and (min-width: 1150px) {
  .loadingStoryItem {
    border-radius: 50%;
    min-width: calc(100% / 10 - 10px);
    aspect-ratio: 1/1;
    background-color: var(--blackOp07);
    overflow: hidden;
  }
}
