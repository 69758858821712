.fullVideoCloser {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  background-color: var(--blackOpB2);
  z-index: 1000;
  display: none;
  backdrop-filter: blur(3px);
}
.fullVideoIcon {
  pointer-events: none;
  position: absolute;
  left: 20px;
  top: 20px;
  color: var(--bgClr);
  width: 40px;
  height: 40px;
}
.fullVideoCloser.show {
  display: block;
}
.fullVideoItem {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 87%;
  max-width: 1000px;
  transform: translate(-50%, -50%);
  z-index: 1002;
  border-radius: 7px;
  overflow: hidden;
  display: none;
}
.fullVideoItem.show {
  display: -webkit-flex;
  display: flex;
}
@media screen and (min-width: 768px) {
  .fullVideoIcon {
    left: 45px;
    top: 45px;
  }
}
