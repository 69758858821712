/* loadingAboutSection */
.loadingAboutSection {
  margin-top: 20px;
  gap: 20px;
}
.navLoading {
  width: 100%;
  height: 45px;
  border-radius: 7px;
  overflow: hidden;
  background-color: var(--blackOp06);
  max-width: 550px;
}
.aboutIntroDiv {
  gap: 20px;
}
.aboutIntroLeftDiv {
  height: 320px;
  width: 80%;
  max-width: 250px;
  border-radius: 7px;
  background-color: var(--blackOp06);
  overflow: hidden;
  margin: 0 auto;
}
.aboutIntroRightDiv {
  width: 100%;
  height: 350px;
  border-radius: 7px;
  background-color: var(--blackOp06);
  overflow: hidden;
}
.videoLoadingDiv {
  display: none;
}
@media screen and (min-width: 768px) {
  .navLoading {
    margin-top: 10px;
  }
  .aboutIntroDiv {
    grid-template-columns: 1fr 250px;
    align-items: center;
    gap: 30px;
  }
  .aboutIntroLeftDiv {
    order: 2;
    width: 100%;
    height: 350px;
  }
  .videoLoadingDiv {
    height: 350px;
    width: 100%;
    border-radius: 7px;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    background-color: var(--blackOp06);
    margin-top: 10px;
  }
}
/* aboutIntroSection */
.aboutIntroSection {
  margin-top: 20px;
  gap: 20px;
  padding: 0 20px;
}
.aboutIntroTitle {
  font-size: 1.6em;
}
.aboutDescription {
  padding: 20px 20px 0;
  margin-top: 20px;
  border-top: 1px solid var(--blackOp20);
}
.aboutDescription .blogsContent {
  padding: 0;
}
.aboutLeftDiv {
  display: none;
}
@media screen and (min-width: 768px) {
  .aboutIntroSection {
    grid-template-columns: 1fr 350px;
    gap: 40px;
    align-items: center;
  }
  .aboutIntroTitle {
    font-size: 2em;
  }
  .aboutIntroSection .courseFirstLeftDiv {
    margin-top: 0;
  }
  .aboutIntroSection .courseLeftDivImg {
    aspect-ratio: 2/1.2;
  }
}
@media screen and (min-width: 968px) {
  .aboutDescription {
    grid-template-columns: 1fr 350px;
    gap: 20px;
  }
  .aboutLeftDiv {
    display: -webkit-box;
    display: flex;
    height: fit-content;
    position: sticky;
    top: 100px;
  }
  .aboutLeftDivImg {
    width: 100%;
  }
}
@media screen and (min-width: 1100px) {
  .aboutIntroSection {
    grid-template-columns: 1fr 400px;
    gap: 10px;
    margin-top: 10px;
    padding: 0 40px;
  }
  .aboutDescription {
    padding: 40px 40px 0;
    border-top: none;
    position: relative;
    margin-top: 40px;
  }
  .aboutDescription::before {
    content: "";
    width: calc(100% - 80px);
    margin: 0 40px;
    background-color: var(--blackOp20);
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .introShortDesc {
    max-width: 550px;
  }
  .aboutDescription {
    grid-template-columns: 1fr 450px;
    gap: 80px;
    align-items: center;
  }
}
