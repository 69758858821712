.videoInfoSection {
  background-color: var(--bgClr);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 15px var(--blackOp12);
  margin-top: 20px;
  gap: 20px;
}
.videoInfoShortDesc {
  text-align: justify;
}
.videoSectionInfoDiv .addToCartDiv {
  margin-top: 10px;
}
.dlFileDiv {
  padding: 15px;
  box-shadow: 0 -3px 15px var(--blackOp20);
}
.dlVideo {
  height: 45px;
  background-color: var(--mainClr1);
  border-radius: 7px;
  gap: 5px;
}
.dlVideo svg {
  transform: translateY(-2px);
}
@media screen and (min-width: 768px) {
  .videoInfoSection {
    grid-template-columns: 1fr 300px;
    gap: 30px;
  }
  .videoInfoSection .courseFirstLeftDiv {
    order: 2;
  }
  .videoSectionInfoDiv .addToCartDiv {
    margin-top: 20px;
    max-width: 600px;
  }
  .dlFileDiv {
    box-shadow: none;
    padding: 0;
    width: 70%;
  }
}
@media screen and (min-width: 968px) {
  .videoInfoSection {
    grid-template-columns: 1fr 350px;
    gap: 60px;
    padding: 20px;
  }
  .videoSectionInfoDiv .addToCartDiv {
    margin-top: auto;
  }
  .videoInfoSection .courseFirstLeftDiv {
    margin-top: 10px;
  }
  .addToCartDiv.dlFile {
    margin: auto auto 0 0;
  }
  .dlFileDiv {
    margin-right: auto;
  }
}
/* videoMiddleDivSection */
.videoMiddleDivSection {
  margin-top: 20px;
  gap: 20px;
}
.videoDescriptionDiv {
  background-color: var(--bgClr);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 15px var(--blackOp12);
}
@media screen and (min-width: 768px) {
  .videoMiddleDivSection {
    grid-template-columns: 1fr 300px;
  }
}
@media screen and (min-width: 1100px) {
  .videoMiddleDivSection {
    grid-template-columns: 1fr 350px;
  }
}
