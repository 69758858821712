.podcastDetailsLoadingSection {
  margin-top: 20px;
}
.podcastDetailsRouteLoading {
  background-color: var(--blackOp06);
  width: 100%;
  height: 45px;
  border-radius: 7px;
  overflow: hidden;
}
.podcastDetailsLoadingContent {
  margin-top: 30px;
}
.podcastDetailsImgLoading {
  width: 50%;
  max-width: 300px;
  aspect-ratio: 1/1.5;
  border-radius: 7px;
  background-color: var(--blackOp06);
  margin: 0 auto;
  overflow: hidden;
}
.podcastInfoLoadingDiv {
  margin-top: 30px;
  gap: 10px;
}
.podcastTextLoading {
  height: 28px;
  background-color: var(--blackOp06);
  border-radius: 7px;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .podcastDetailsLoadingSection {
    padding: 0 40px;
    margin-top: 40px;
  }
  .podcastDetailsLoadingContent {
    grid-template-columns: 2fr 1.2fr;
    gap: 40px;
    align-items: flex-start;
    margin-top: -10px;
  }
  .podcastDetailsImgLoading {
    order: 2;
    transform: translateY(-40px);
    margin: 0;
    margin-right: auto;
    width: 60%;
  }
  .podcastDetailsRouteLoading {
    width: 60%;
  }
  .podcastTextLoading {
    margin: 0;
  }
}
/* podcastDetailsSection */
.podcastDetailsSection {
  margin-top: 40px;
  position: relative;
  gap: 20px;
}
.podcastLeftDiv {
  align-items: center;
  gap: 20px;
}
.podcastBtnsDiv {
  width: 70%;
  max-width: 250px;
  transform: translateY(135px);
}
.podcastDetailsMainImg {
  width: 70%;
  max-width: 250px;
  border-radius: 7px;
  box-shadow: -3px 3px 20px var(--blackOp20);
  height: auto;
}
.playPodcast {
  padding: 0 15px;
  width: 70%;
  max-width: 250px;
  background-color: var(--mainClr1);
  border-radius: 7px;
  height: 40px;
}
.podcastRightDiv {
  margin-top: -80px;
}
.podcastPlayDiv {
  grid-template-columns: 1fr 40px;
  width: 100%;
  align-items: center;
  gap: 5px;
}
.podcastPlayDiv .playPodcast {
  width: 100%;
}
.dlPodcastBtn {
  width: 100%;
  height: 100%;
  background-color: var(--mainClr1);
  border-radius: 7px;
  color: var(--bgClr);
}
.playPodcastText {
  color: var(--bgClr);
  gap: 8px;
  font-size: 1.15em;
  font-weight: 600;
  pointer-events: none;
}
.playPodcastText svg {
  transform: translateY(-1px);
}
.podcastDetailsName {
  text-align: center;
  margin-top: 15px;
}
.podcastAuthor {
  text-align: center;
  margin-top: 3px;
  width: fit-content;
  margin: 0 auto;
}
.podcastInfoDiv {
  margin-top: 30px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.podcastCategory,
.podcastDetailsDuration {
  gap: 5px;
  padding: 3px 10px;
  border: 1px solid var(--blackOp20);
  border-radius: 7px;
}
.podcastCategory svg,
.podcastDetailsDuration svg {
  transform: translateY(-1px);
}
.podcastDescriptionDiv {
  margin-top: 100px;
  padding-bottom: 80px;
}
.podcastDescriptionTitle {
  font-size: 1.35em;
  color: var(--clr3);
}
.podcastDescriptionDivList {
  margin-top: 10px;
  max-height: 80px;
  overflow: hidden;
  position: relative;
}
.podcastDescriptionDivList::after {
  content: "";
  height: 60px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, var(--bgClr), transparent);
  z-index: 10;
}
.podcastDescriptionDivList > div {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.podcastDescriptionDivList * {
  text-align: justify;
}
.podcastDescriptionDivList p,
.podcastDescriptionDivList h1,
.podcastDescriptionDivList h4,
.podcastDescriptionDivList h3,
.podcastDescriptionDivList h5,
.podcastDescriptionDivList h6,
.podcastDescriptionDivList span {
  color: var(--clr5);
}
.podcastDescriptionDivList a {
  color: var(--blueClr2);
  border-bottom: 1px solid var(--blueClr2);
  width: fit-content;
}
.showFullDescription {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  height: 35px;
  align-items: center;
  z-index: 102;
  gap: 5px;
  padding: 0 10px;
  color: var(--bgClr);
  border-radius: 7px;
  background-color: var(--mainClr1);
  transition: background-color 0.4s;
}
.showFullDescription:hover {
  background-color: var(--mainClr4);
}
.showFullDescription svg {
  height: 17px;
  width: 17px;
}
.podcastDescriptionDiv.full {
  padding-bottom: 30px;
}
.podcastDescriptionDiv.full .showFullDescription,
.podcastDescriptionDiv.full .podcastDescriptionDivList:after {
  display: none;
}
.podcastDescriptionDiv.full .podcastDescriptionDivList {
  max-height: unset;
}
.addToCartDiv {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: var(--bgClr);
  z-index: 500;
}
.addToCartSubDiv {
  grid-template-columns: 1.5fr 1fr;
  height: 70px;
  align-items: center;
  gap: 20px;
  padding: 0 25px;
  box-shadow: 0 -3px 15px var(--blackOp20);
}
.removeFromCart {
  background-color: var(--redClr);
  height: 45px;
  border-radius: 9px;
  gap: 7px;
  padding-top: 2px;
}
.addToCartBtn {
  background-color: var(--mainClr1);
  height: 45px;
  border-radius: 9px;
  gap: 7px;
  padding-top: 2px;
}
.removeFromCart svg,
.addToCartBtn svg {
  transform: translateY(-2px);
}
.addToCartOffPrice {
  align-items: flex-end;
}
.addToCartOffPrice,
.addToCartRealPrice {
  margin-right: auto;
  gap: 5px;
}
.addToCartOffPrice .value {
  text-decoration: line-through;
  text-decoration-color: var(--redClr);
}
.addToCartPriceDiv .perTitle {
  display: none;
}
.addToCartOffPercent {
  background-color: var(--redClr);
  position: absolute;
  right: 0;
  top: 2px;
  padding: 2px 10px 0;
  color: var(--bgClr);
  border-radius: 20px;
}
.addToCartOffPercent .value {
  gap: 3px;
}
.addToCartOffPercent .value svg {
  width: 12px;
  height: 12px;
  transform: translateY(-1px);
}
@media screen and (min-width: 768px) {
  .podcastDetailsSection {
    grid-template-columns: 1fr 300px;
    margin-top: 10px;
    align-items: flex-start;
    gap: 30px;
  }
  .podcastLeftDiv {
    order: 2;
    position: sticky;
    top: 110px;
    height: fit-content;
  }
  .podcastRightDiv {
    margin-top: 0;
  }
  .podcastDetailsName {
    text-align: right;
    margin-top: 0;
  }
  .podcastAuthor {
    margin: 5px 0 0;
  }
  .podcastInfoDiv {
    justify-content: flex-start;
    margin-top: 20px;
  }
  .podcastDescriptionDiv {
    margin-top: 40px;
    position: relative;
  }
  .podcastDescriptionDiv::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--blackOp12);
    position: absolute;
    left: 0;
    top: -20px;
  }
  .showFullDescription {
    bottom: 30px;
  }
  .podcastBtnsDiv {
    width: 70%;
    max-width: unset;
    transform: unset;
  }
  .podcastDetailsMainImg {
    width: 70%;
    max-width: unset;
  }
  .addToCartDiv {
    position: unset;
    left: unset;
    bottom: unset;
    width: 100%;
    margin-top: 0;
  }
  .addToCartSubDiv {
    box-shadow: unset;
    background-color: transparent;
    border: 1px dashed var(--blackOp20);
    border-radius: 7px;
    padding: 10px 15px;
    gap: 30px;
    height: unset;
    max-width: 650px;
  }
}
@media screen and (min-width: 1100px) {
  .podcastDetailsSection {
    grid-template-columns: 1fr 400px;
    gap: 80px;
  }
}
@media screen and (min-width: 1250px) {
  .podcastDetailsMainImg,
  .podcastBtnsDiv {
    margin: 0 auto 0 0;
  }
}
/* relativePodcastsSection */
.relativePodcastsSection {
  margin-top: 40px;
  position: relative;
}
.relativePodcastsSection::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  top: -20px;
  background-color: var(--blackOp20);
  z-index: 10;
}
.relativePodcastsSection .sectionHeader {
  display: none;
}
.relativePodcastsSection .podcastSection {
  margin-top: 0;
}
.relativePodcastsSection .podcastSection {
  max-width: unset;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .relativePodcastsSection {
    margin-top: 120px;
  }
  .relativePodcastsSection::before {
    top: -40px;
  }
}
